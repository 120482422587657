<form
  autocomplete="off"
  class="h-100"
  [formGroup]="changePasswordForm"
  novalidate
  (submit)="onSubmit()"
>
  <nb-card>
    <nb-card-header>
      <div class="d-flex justify-content-between">
        Change password
        <button
          (click)="dismiss()"
          type="button"
          class="btn-close"
          aria-label="Close"
        ></button>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="mb-3">
        <label for="input-old-password" class="form-label label required"
          >Old Password</label
        >
        <nb-form-field>
          <input
            formControlName="oldpassword"
            [type]="getInputType('oldpassword')"
            name="oldpassword"
            nbInput
            fullWidth
            id="input-old-password"
            (ngModelChange)="checkPasswordSyntax($event, 'oldpassword')"
            placeholder="Old Password"
            [status]="
              changePasswordForm.controls['oldpassword'].invalid &&
              (changePasswordForm.controls['oldpassword'].dirty ||
                changePasswordForm.controls['oldpassword'].touched)
                ? 'danger'
                : 'basic'
            "
            autocomplete="off"
          />
          <button
            type="button"
            nbSuffix
            nbButton
            ghost
            (click)="toggleShowPassword('oldpassword')"
          >
            <nb-icon
              [icon]="showOldPassword ? 'eye-off-outline' : 'eye-outline'"
              pack="eva"
              [attr.aria-label]="
                showOldPassword ? 'hide password' : 'show password'
              "
            >
            </nb-icon>
          </button>
        </nb-form-field>
        <ng-container
          *ngIf="
            changePasswordForm.controls['oldpassword'].invalid &&
            (changePasswordForm.controls['oldpassword'].dirty ||
              changePasswordForm.controls['oldpassword'].touched)
          "
        >
          <p
            class="caption status-danger"
            *ngIf="
              changePasswordForm.controls['oldpassword'].hasError('required')
            "
          >
            Old Password is required.
          </p>
          <p
            class="caption status-danger"
            *ngIf="
              changePasswordForm.controls['oldpassword'].hasError('notvalid')
            "
          >
            Old Password is not valid.
          </p>
          <p
            class="caption status-danger"
            *ngIf="
              changePasswordForm.controls['oldpassword'].hasError('minLength')
            "
          >
            Old Password must have at least 6 characters including: uppercase,
            lowercase, number.
          </p>
          <p
            class="caption status-danger"
            *ngIf="
              changePasswordForm.controls['oldpassword'].hasError('maxLength')
            "
          >
            Old Password must have at most 15 characters including: uppercase,
            lowercase, number.
          </p>
          <p
            class="caption status-danger"
            *ngIf="
              changePasswordForm.controls['oldpassword'].hasError('uppercase')
            "
          >
            Old Password must have at least 1 uppercase character.
          </p>
          <p
            class="caption status-danger"
            *ngIf="
              changePasswordForm.controls['oldpassword'].hasError('lowercase')
            "
          >
            Old Password must have at least 1 lowercase character.
          </p>
          <p
            class="caption status-danger"
            *ngIf="changePasswordForm.controls['oldpassword'].hasError('digit')"
          >
            Old Password must have at least 1 digit character.
          </p>
          <p
            class="caption status-danger"
            *ngIf="
              changePasswordForm.controls['oldpassword'].errors.serverError
            "
          >
            {{ changePasswordForm.controls["oldpassword"].errors.serverError }}
          </p>
        </ng-container>
      </div>
      <div class="mb-3">
        <label for="input-new-password" class="form-label label required"
          >New Password</label
        >
        <nb-form-field>
          <input
            formControlName="newpassword"
            [type]="getInputType('newpassword')"
            nbInput
            fullWidth
            id="input-new-password"
            (ngModelChange)="checkPasswordSyntax($event, 'newpassword')"
            placeholder="New Password"
            [status]="
              changePasswordForm.controls['newpassword'].invalid &&
              (changePasswordForm.controls['newpassword'].dirty ||
                changePasswordForm.controls['newpassword'].touched)
                ? 'danger'
                : 'basic'
            "
            autocomplete="off"
            name="newpassword"
          />
          <button
            type="button"
            nbSuffix
            nbButton
            ghost
            (click)="toggleShowPassword('newpassword')"
          >
            <nb-icon
              [icon]="showNewPassword ? 'eye-off-outline' : 'eye-outline'"
              pack="eva"
              [attr.aria-label]="
                showNewPassword ? 'hide password' : 'show password'
              "
            >
            </nb-icon>
          </button>
        </nb-form-field>
        <ng-container
          *ngIf="
            changePasswordForm.controls['newpassword'].invalid &&
            (changePasswordForm.controls['newpassword'].dirty ||
              changePasswordForm.controls['newpassword'].touched)
          "
        >
          <p
            class="caption status-danger"
            *ngIf="
              changePasswordForm.controls['newpassword'].hasError('required')
            "
          >
            New Password is required.
          </p>
          <p
            class="caption status-danger"
            *ngIf="
              changePasswordForm.controls['newpassword'].hasError('notvalid')
            "
          >
            New Password is not valid.
          </p>
          <p
            class="caption status-danger"
            *ngIf="
              changePasswordForm.controls['newpassword'].hasError('minLength')
            "
          >
            New Password must have at least 6 characters including: uppercase,
            lowercase, number.
          </p>
          <p
            class="caption status-danger"
            *ngIf="
              changePasswordForm.controls['newpassword'].hasError('maxLength')
            "
          >
            New Password must have at most 15 characters including: uppercase,
            lowercase, number.
          </p>
          <p
            class="caption status-danger"
            *ngIf="
              changePasswordForm.controls['newpassword'].hasError('uppercase')
            "
          >
            New Password must have at least 1 uppercase character.
          </p>
          <p
            class="caption status-danger"
            *ngIf="
              changePasswordForm.controls['newpassword'].hasError('lowercase')
            "
          >
            New Password must have at least 1 lowercase character.
          </p>
          <p
            class="caption status-danger"
            *ngIf="changePasswordForm.controls['newpassword'].hasError('digit')"
          >
            New Password must have at least 1 digit character.
          </p>
          <p
            class="caption status-danger"
            *ngIf="
              changePasswordForm.controls['newpassword'].hasError(
                'sameOldPassword'
              )
            "
          >
            New Password and Old Password must not be the same.
          </p>
          <p
            class="caption status-danger"
            *ngIf="
              changePasswordForm.controls['newpassword'].errors.serverError
            "
          >
            {{ changePasswordForm.controls["newpassword"].errors.serverError }}
          </p>
        </ng-container>
      </div>
      <div class="mb-3">
        <label for="input-confirm-password" class="form-label label required"
          >Confirm Password</label
        >
        <nb-form-field>
          <input
            formControlName="confirmpassword"
            [type]="getInputType('confirmpassword')"
            nbInput
            fullWidth
            id="input-confirm-password"
            (ngModelChange)="checkPasswordSyntax($event, 'confirmpassword')"
            placeholder="Confirm Password"
            [status]="
              changePasswordForm.controls['confirmpassword'].invalid &&
              (changePasswordForm.controls['confirmpassword'].dirty ||
                changePasswordForm.controls['confirmpassword'].touched)
                ? 'danger'
                : 'basic'
            "
            autocomplete="off"
            name="confirmpassword"
          />
          <button
            type="button"
            nbSuffix
            nbButton
            ghost
            (click)="toggleShowPassword('confirmpassword')"
          >
            <nb-icon
              [icon]="showConfirmPassword ? 'eye-off-outline' : 'eye-outline'"
              pack="eva"
              [attr.aria-label]="
                showConfirmPassword ? 'hide password' : 'show password'
              "
            >
            </nb-icon>
          </button>
        </nb-form-field>
        <ng-container
          *ngIf="
            changePasswordForm.controls['confirmpassword'].invalid &&
            (changePasswordForm.controls['confirmpassword'].dirty ||
              changePasswordForm.controls['confirmpassword'].touched)
          "
        >
          <p
            class="caption status-danger"
            *ngIf="
              changePasswordForm.controls['confirmpassword'].hasError(
                'required'
              )
            "
          >
            Confirm Password is required.
          </p>
          <p
            class="caption status-danger"
            *ngIf="
              changePasswordForm.controls['confirmpassword'].hasError(
                'notvalid'
              )
            "
          >
            Confirm Password is not valid.
          </p>
          <p
            class="caption status-danger"
            *ngIf="
              changePasswordForm.controls['confirmpassword'].hasError(
                'notmatch'
              )
            "
          >
            New Password and Confirm Password does not match.
          </p>
          <p
            class="caption status-danger"
            *ngIf="
              changePasswordForm.controls['confirmpassword'].errors.serverError
            "
          >
            {{
              changePasswordForm.controls["confirmpassword"].errors.serverError
            }}
          </p>
        </ng-container>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button
        size="small"
        [disabled]="!changePasswordForm.valid || isLoading"
        class="float-end"
        type="submit"
        status="primary"
        class="me-2 small float-end"
        nbButton
      >
        <nb-icon icon="save-outline"></nb-icon>Save
      </button>
    </nb-card-footer>
  </nb-card>
</form>
