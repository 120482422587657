import { IApplicationData } from "./common";
export class Forms implements IApplicationData {
  constructor() {
    this.name = "Forms";
    this.option = 1;
    this.transition = true;
    this.markRequiredFields = true;
    this.skip = false;
    this.url = "";
    this.questions = "[]";
    this.title = "";
    this.description = "";
    this.buttonLabel = "Send";
    this.errorMessage = "This field is required";
  }
  name: string;
  url: string;
  title: string;
  description: string;
  buttonLabel: string;
  errorMessage: string;
  option: number;
  transition: boolean;
  skip: boolean;
  markRequiredFields: boolean;
  questions: string;
}
