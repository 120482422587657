import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import {
  NbDialogRef,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from "@nebular/theme";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import {
  CheckDatasetNameCommand,
  CreateDatasetCommand,
  DataSetClient,
  DatasetDto,
} from "../../../System-api";
import { noWhitespaceValidator } from "../../../@core/utils/helpers";

@Component({
  selector: "dataset",
  templateUrl: "./dataset.component.html",
  styleUrls: ["./dataset.component.scss"],
})
export class DatasetComponent implements OnInit {
  @ViewChild("inputName") inputName: ElementRef<any>;
  datasetForm: UntypedFormGroup;
  dataset: DatasetDto;
  labels: any[];
  command: CreateDatasetCommand;
  isNameLoading: boolean = false;
  currentName: string;
  timeout: any = null;
  isExist: boolean = false;
  onAdd = new EventEmitter();
  isLoading = false;
  constructor(
    private toastrService: NbToastrService,
    private datasetClient: DataSetClient,
    private formBuilder: RxFormBuilder,
    private ref: NbDialogRef<DatasetComponent>
  ) {}
  ngOnInit(): void {
    this.createForm();

    this.labels = [];
    this.command = new CreateDatasetCommand();
    this.command.name = "";
    this.command.labels = "";
    this.command.chartType = "Bar";
    this.command.lastResult = false;

    if (this.dataset != undefined) {
      this.command.chartType = this.dataset.chartType;
      this.command.id = this.dataset.id;
      this.command.name = this.dataset.name;
      this.command.lastResult = this.dataset.lastResult;
      this.currentName = this.dataset.name;

      this.dataset.labels.split(",").forEach((element) => {
        const obj = { display: element, value: element };
        if (obj.value != "") {
          this.labels.push(obj);
        }
      });
    }
  }

  createForm() {
    this.datasetForm = this.formBuilder.group({
      name: [
        "",
        [
          RxwebValidators.required(),
          noWhitespaceValidator,
          Validators.maxLength(50),
          RxwebValidators.pattern({
            expression: {
              regex: /^[^\\\/:*?"<>|]+$/,
            },
          }),
        ],
      ],
      chartType: [""],
      labels: [""],
    });
  }
  toggle(e) {
    this.command.lastResult = e;
  }
  checkDatasetName(value) {}

  handleFocus() {
    setTimeout(() => {
      this.inputName?.nativeElement?.focus();
    }, 0);
  }

  onSubmit() {
    setTimeout(() => {
      if (this.datasetForm.valid) {
        if (this.labels.length > 0) {
          this.command.labels = this.labels.map((obj) => obj.value).join(",");
        }
        this.command.name = this.command.name?.trim();
        this.isLoading = true;
        this.datasetClient.create(this.command).subscribe(
          (result) => {
            this.isLoading = false;
            this.showToast(result);
            if (result) {
              this.onAdd.emit(true);
              this.dismiss();
            } else {
              this.onAdd.emit(false);
            }
          },
          (error) => {
            this.isLoading = false;
            this.onAdd.emit(false);
            if (error.status == 422) {
              this.setValidationErrors(error.response);
            }
          }
        );
      }
    }, 100);
  }
  setValidationErrors(errorData) {
    var errorData = JSON.parse(errorData);
    if (errorData) {
      for (const [key, value] of Object.entries(errorData)) {
        const fieldName = key.toLowerCase(); // Ensure case matches form control names
        const errorMessage = value[0];
        if (this.datasetForm.get(fieldName)) {
          const control = this.datasetForm.get(fieldName);
          control.setErrors({ serverError: errorMessage });
          control.markAsDirty();
        }
      }
    }
  }
  dismiss() {
    this.ref.close();
  }

  showToast(result) {
    if (result == true) {
      this.toastrService.show("Save dataset successfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show("Save dataset unsuccessfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
}
