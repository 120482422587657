<nb-card style="width: 400px;">
  <nb-card-header>
    <div class="d-flex justify-content-between">
      {{ title }}
      <button (click)="dismiss()" type="button" class="btn-close" aria-label="Close">
      </button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="mb-2">
      <label class="form-label label">Audio</label>
      <audio class="col-md-12" controls [src]="audioUrl"><source /></audio>
    </div>
  </nb-card-body>
</nb-card>