import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

import {
  NbAuthService,
  NbAuthJWTToken,
  NbAuthOAuth2JWTToken,
} from "@nebular/auth";
import { NbAclService, NbRoleProvider } from "@nebular/security";
import { DataService } from "../@core/utils/data.service";
import { InstructionService } from "../@core/utils/instruction.service";
import { EventBusService } from "../@core/utils/eventbus.service";

@Injectable()
export class RoleProvider implements NbRoleProvider {
  private skipIntro: boolean;
  constructor(
    private authService: NbAuthService,
    private eventBusService: EventBusService,
    private introService: InstructionService,
    private dataService: DataService,
    private nbAclService: NbAclService
  ) {}

  getRole(): any {
    return this.authService.onTokenChange().pipe(
      map((token: NbAuthJWTToken) => {
        if (token.getPayload() != undefined) {
          this.dataService.userName = token.getPayload().name;
          // check lastlogin to show introduction.
          this.eventBusService.on("skipIntro", (data: boolean) => {
            this.skipIntro = data;
          });
          if (this.skipIntro) {
            this.dataService.NewUser = false;
          } else {
            this.dataService.NewUser =
              token.getPayload()["lastlogin"] == "" ? true : false;
          }
          let roleUser = "guest";
          const temp = token.getPayload()["role"]?.split("-", 2);

          if (temp.length == 2) {
            if (token.isValid()) {
              if (temp[1] != "SuperAdmin" && temp[1] != "Agent") {
                roleUser = "Admin";
              } else {
                roleUser = temp[1];
              }
              this.dataService.CompanyId = temp[0];
            } else {
              roleUser = null;
              this.dataService.userName = null;
            }
          }

          let permission = token.getPayload()["permission"] || "[]";

          permission = JSON.parse(permission);
          let accessControl: any = {
            [roleUser]: {
              parent: "Default",
              access: permission,
            },
          };
          if (roleUser == "Admin" || roleUser == "guest") {
            accessControl[roleUser].accessDefault = ["dashboard"];
          }
          this.nbAclService.setAccessControl(accessControl);
          return roleUser;
        }
      })
    );
  }
}
