import { Component, OnInit } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { ResponseDefine } from "../../../../@core/model/response-define";

@Component({
  selector: "ngx-failure-case-defination",
  templateUrl: "./failure-case-defination.component.html",
  styleUrls: ["./failure-case-defination.component.scss"],
})
export class FailureCaseDefinationComponent implements OnInit {
  data: ResponseDefine = new ResponseDefine();
  listFail: any;
  item: any;
  isUpdate: boolean = false;
  updateData = null;
  constructor(private ref: NbDialogRef<FailureCaseDefinationComponent>) {}

  ngOnInit(): void {
    this.listFail = ["Other", "No Input", "Not Match"];
    if (this.item) {
      this.item.forEach((element) => {
        if (element.value) {
          if (this.isUpdate && element.value == this.updateData) {
            this.data.method = this.updateData;
          } else {
            var index = this.listFail.indexOf(element.value);
            if (index > -1) {
              this.listFail.splice(index, 1);
            }
          }
        }
      });
      if (this.listFail.length == 1) {
        this.data.method = this.listFail[0];
        this.dismiss();
      }
    }
  }

  ngOnDestroy(): void {
    this.ref.close(this.data);
  }

  dismiss() {
    this.ref.close(this.data);
  }
}
