import { IApplicationData } from "./common";
export class SetLanguage implements IApplicationData {
  constructor() {
    this.name = "Set Language";
    this.language = "";
    this.option = "listlang";
    this.attribute = "";
    this.isRequired = false;
    this.checkFields = false;
  }
  name: string;
  language: any;
  option: string;
  attribute: string;
  isRequired: boolean;
  checkFields: boolean;
}
