import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { NbAuthService } from "@nebular/auth";
import { Observable, catchError, tap } from "rxjs";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private injector: Injector, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap(() => { },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if ((err.status === 401) || (err.status === 403)) {
                        if (err.status === 401) {
                            localStorage.removeItem("auth_app_token");
                        }
                        this.router.navigate(['/auth/login']);
                    } else {
                        return;
                    }
                }
            })
        );
    }

    protected get authService(): NbAuthService {
        return this.injector.get(NbAuthService);
    }
}