import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpRequest,
} from "@angular/common/http";
import { CoreModule } from "./@core/core.module";
import { ThemeModule } from "./@theme/theme.module";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import {
  NbChatModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
  NbCardModule,
  NbDatepickerModule,
  NbTimepickerModule,
  NbThemeModule,
  NbGlobalPhysicalPosition,
} from "@nebular/theme";
import {
  NbAuthModule,
  NbPasswordAuthStrategy,
  NbAuthJWTToken,
  NbAuthJWTInterceptor,
  NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
} from "@nebular/auth";
import { NbRoleProvider, NbSecurityModule } from "@nebular/security";
import { RoleProvider } from "./security/role.provider";
import { ErrorPageModule } from "./error-page/error-page.module";
import { LoadingInterceptor } from "./@core/utils/loading-interceptor.service";
import { EventBusService } from "./@core/utils/eventbus.service";
import { CallFlowsDesignModule } from "./callflows-design/callflows-design.module";
import { ActionModule } from "./callflows-design/actions/action.module";
import { NgxEchartsModule } from "ngx-echarts";
import { TextareaHighlightModule } from "./shared/textarea-highlight/textarea-highlight.module";
import { UppyAngularDashboardModule } from "@uppy/angular";
import { CustomInterceptor } from "./security/custom-interceptor.provider";
import { TokenInterceptor } from "./security/token-interceptor.provider";

@NgModule({
  declarations: [AppComponent],
  imports: [
    NbThemeModule.forRoot(),
    BrowserModule,
    NbCardModule,
    NgxEchartsModule.forRoot({
      echarts: () => import("echarts"),
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NbEvaIconsModule,
    UppyAngularDashboardModule,
    NbDialogModule.forRoot(),
    ThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NbDialogModule.forChild(),
    NbWindowModule.forChild(),
    NbToastrModule.forRoot({
      position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
    }),
    TextareaHighlightModule,
    NbChatModule.forRoot({
      messageGoogleMapKey: "AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY",
    }),
    CoreModule.forRoot(),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: "email",
          token: {
            class: NbAuthJWTToken,
            key: "token",
          },
          refreshToken: {
            method: "get",
            endpoint: "/system/auth/refresh-token",
            requireValidToken: false,
            redirect: {
              success: "/auth/login",
              failure: "/auth/login",
            },
            defaultErrors: ["Unauthorized."],
            defaultMessages: ["Your token has been successfully refreshed."],
          },
          baseEndpoint: "",
          login: {
            defaultErrors: null,
            endpoint: "/system/auth/login",
          },
          register: {
            endpoint: "/system/auth/register",
            redirect: {
              success: "/portal/number/mynumber",
            },
          },
          requestPass: {
            endpoint: "/system/auth/request-password",
          },
          resetPass: {
            endpoint: "/system/auth/reset-password",
            redirect: {
              success: "/auth/login",
              failure: null,
            },
          },
          logout: {
            endpoint: "",
            redirect: {
              success: "/auth/login",
              failure: null,
            },
          },
        }),
      ],
    }),
    NbSecurityModule.forRoot({
      accessControl: {
        Default: {
          access: ["home", "error", "menu", "myaccount"],
        },
      },
    }),
    ActionModule,
    ErrorPageModule,
    CallFlowsDesignModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
      useValue: function (req: HttpRequest<any>) {
        if (req.url === "/system/auth/login") {
          return true;
        } else if (req.url === "/system/auth/refresh-token") {
          return true;
        }
        return false;
      },
    },
    { provide: NbRoleProvider, useClass: RoleProvider },
    EventBusService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
