import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
    selector: 'error-401',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss']
})

export class UnauthorizedComponent {
    constructor(private route: Router) { }
    returnHomepage() {
        this.route.navigate(['/portal/agent-workspace']);
    }
}