import { Component, OnInit, Directive } from "@angular/core";
import {
  NbDialogRef,
  NbToastrService,
  NbGlobalPhysicalPosition,
} from "@nebular/theme";
import {
  AgentClient,
  CheckExtensionExistQuery,
  CreateSipAccountCommand,
} from "../../System-api";
import { UntypedFormGroup } from "@angular/forms";
import { RxFormBuilder } from "@rxweb/reactive-form-validators";
import { ValidInput } from "../../@core/utils/helpers";

@Component({
  selector: "ngx-create-sipaccount",
  templateUrl: "./create-sipaccount.component.html",
  styleUrls: ["./create-sipaccount.component.scss"],
})
export class CreateSipaccountComponent implements OnInit {
  createSipForm: UntypedFormGroup;
  companyId: number;

  constructor(
    protected ref: NbDialogRef<CreateSipaccountComponent>,
    private agentClient: AgentClient,
    private formBuilder: RxFormBuilder,
    private toastrService: NbToastrService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.agentClient.getCompanyId().subscribe((rs) => {
      this.companyId = rs;
    });
  }

  createForm() {
    this.createSipForm = this.formBuilder.group({
      username: ["", ValidInput.username],
      extension: ["", ValidInput.extension],
      password: ["", ValidInput.password],
    });
  }

  dismiss() {
    this.ref.close(false);
  }
  onSubmit() {
    // this.agentClient.createSip(new CreateSipAccountCommand({
    // 	userName: this.createSipForm.controls['password'].value,
    // 	password: this.createSipForm.controls['extension'].value,
    // 	extension: this.createSipForm.controls['password'].value,
    // })).subscribe(rs=>{
    // 	this.showToast(rs);
    // 	if(rs)
    // 	{
    // 		this.dismiss();
    // 	}
    // })
  }
  showToast(result) {
    if (result) {
      this.toastrService.show("Create account successfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show("Create account unsuccessfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }

  checkExtension(value) {
    this.agentClient
      .checkExistExtension(
        new CheckExtensionExistQuery({
          id: 0,
          extension: value,
        })
      )
      .subscribe((data) => {
        if (data) {
          this.createSipForm.controls["extension"].setErrors({
            isExist: data,
          });
        }
      });
  }
  checkUsername(value) {
    this.agentClient.checkExistUserName(value).subscribe((data) => {
      if (data) {
        this.createSipForm.controls["username"].setErrors({
          isExist: data,
        });
      }
    });
  }
}
