<div class="action-config-wrapper">
  <div class="config-action">
    <div class="mb-2">
      <label for="waitSecond" class="form-label label required">Wait Second</label>
      <form [formGroup]="formGroup">
        <input 
          id="waitSecond"             
          type="number"
          formControlName="second"
          placeholder="Wait Second"
          fieldSize="small" 
          nbInput 
          fullWidth 
          [(ngModel)]="data.second" 
          [status]="
              formGroup.controls['second'].invalid &&
              (formGroup.controls['second'].dirty ||
              formGroup.controls['second'].touched)
                ? 'danger'
                : 'basic'
              "/>
         <ng-container
            *ngIf="
              formGroup.controls['second'].invalid &&
              (formGroup.controls['second'].dirty ||
              formGroup.controls['second'].touched)
            ">
            <p
              class="caption status-danger"
              style="margin-bottom: 0"
              *ngIf="
                formGroup.controls['second'].hasError('required') ||
                formGroup.controls['second'].hasError('whitespace')"
            >
              Wait Second is required.
            </p>
       </ng-container>
     </form>
    </div>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
