import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { CheckAttribute } from "../../../../@core/model/check-attribute";
import { NbDialogService, NbWindowRef } from "@nebular/theme";
import { DataService } from "../../../../@core/utils/data.service";
import { DialogAttributeComponent } from "../../other/dialog-attribute/dialog-attribute.component";
import { Observable, map, of } from "rxjs";
import { NgSelectComponent } from "@ng-select/ng-select";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";

@Component({
  selector: "ngx-check-attribute",
  templateUrl: "./check-attribute.component.html",
  styleUrls: ["./check-attribute.component.scss"],
})
export class CheckAttributeComponent implements OnInit {
  listCondition;
  cell: MxCell;
  graph: MxGraph;
  data: CheckAttribute = new CheckAttribute();
  @ViewChild("autoInput") input;
  @ViewChild(NgSelectComponent) selectComponents: NgSelectComponent;
  attributes: string[] = this.dataService.ivrAttributeArray;
  filteredOptions$: Observable<any[]> = of(this.dataService.ivrAttribute);
  cancelAction = false;
  errorMessage: string = "Variable is required.";
  constructor(
    private dialogService: NbDialogService,
    protected windowRef: NbWindowRef,
    public dataService: DataService,
    private graphService: GraphHandlerService
  ) {}

  ngOnInit() {
    this.data.name = this.cell.getAttribute("name", "");
    this.data.attribute = this.cell.getAttribute("attribute", "");
    if (this.data.attribute?.trim() == "" || !this.data.attribute) {
      this.data.attribute = null;
    }

    this.windowRef.onClose.subscribe(() => this.submit());
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  submit() {
    if (!this.cancelAction) {
      this.cell.setAttribute(
        "attribute",
        this.data.attribute ? this.data.attribute : ""
      );
      this.cell.setAttribute("name", this.data.name ? this.data.name : "");

      const checkAttributeCell = this.graph
        .getModel()
        .getCell(this.cell.getId());
      const fieldsRequired = [this.data.attribute];
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        checkAttributeCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }

  selectVariable(value) {
    if (value?.default == "VARIABLE") {
      this.dialogService
        .open(DialogAttributeComponent, { autoFocus: false })
        .onClose.subscribe((rs) => {
          if (rs) {
            this.filteredOptions$ = this.filteredOptions$.pipe(
              map((options) => [...options])
            );
            this.selectComponents.writeValue(rs);
            this.data.attribute = rs;
          }
        });
    }
  }
  getVariable($event) {
    this.data.attribute = $event;
  }
}
