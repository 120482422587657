import { Component, OnInit, Directive } from "@angular/core";

@Component({
  selector: "ngx-app",
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    document.getElementById("splash").style.display = "none";
  }
}
