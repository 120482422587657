import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TextareaHighlightComponent } from "./textarea-highlight.component";
import { FormsModule } from "@angular/forms";
import {
  NbAutocompleteModule,
  NbButtonModule,
  NbCardModule,
  NbIconModule,
  NbInputModule,
  NbPopoverModule,
  NbSelectModule,
} from "@nebular/theme";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NbPopoverModule,
    NbSelectModule,
    NbAutocompleteModule,
    NbCardModule,
    NbInputModule,
    NbButtonModule,
    NbIconModule,
  ],
  declarations: [TextareaHighlightComponent],
  exports: [TextareaHighlightComponent],
})
export class TextareaHighlightModule {}
