import { Injectable } from '@angular/core';

@Injectable()
export class LoadingService {

  constructor() {
  }
  setLoading(loading) {
  }
 
}
