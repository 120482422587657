import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule } from '@nebular/auth';
import { NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { AuthGuard } from './utils/auth-guard.service';
import { DataService } from './utils/data.service';
import { GraphHandlerService } from './utils/graph.service';
import { LoadingService } from './utils/loading.service';
import { SignalRService } from './utils/signalR.service';
import { ChatHubService } from './utils/chathub.service';

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  AuthGuard,
  DataService,
  GraphHandlerService,
  LoadingService,
  SignalRService,
  ChatHubService
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
