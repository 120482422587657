import { IApplicationData } from "./common";
export class WaitAction implements IApplicationData {
  constructor() {
    this.name = "Wait Action";
    this.second = "2";
    this.isRequired = false;
    this.checkFields = false;
  }
  name: string;
  second: string;
  isRequired: boolean;
  checkFields: boolean;
}
