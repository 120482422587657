import { IApplicationData } from "./common";
export class DataSet implements IApplicationData {
  constructor() {
    this.name = "Report";
    this.datasetId = "";
    this.nameDataset = "";
    this.labels = "";
    this.isRequired = true;
    this.note = "";
  }
  name: string;
  datasetId: string;
  nameDataset: string;
  labels: string;
  isRequired: boolean;
  note: string;
}
