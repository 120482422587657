import { Component, Input, OnInit } from "@angular/core";
@Component({
  selector: "set-media-file-modal",
  templateUrl: "./set-media-file-modal.component.html",
  styleUrls: ["./set-media-file-modal.component.scss"],
})
export class SetMediaFileModalComponent implements OnInit {
  showAddButton: boolean;
  constructor() {}
  ngOnInit() {}
  addSentiment() {}
}
