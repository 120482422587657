import { Component, OnInit } from "@angular/core";

import { NbDialogRef } from "@nebular/theme";

@Component({
  selector: "test-param",
  templateUrl: "./test-param.component.html",
  styleUrls: ["./test-param.component.scss"],
})
export class TestParamComponent implements OnInit {
  params;
  paramsOutput = {};
  paramsArray = [];
  constructor(public ref: NbDialogRef<any>) {}
  ngOnInit(): void {
    var paramsArray = this.params.split("|");
    paramsArray.forEach((param) => {
      var obj = {
        name: param,
        value: "",
      };
      this.paramsArray.push(obj);
    });
  }
  confirm() {
    this.paramsArray.forEach((param) => {
      this.paramsOutput[param.name] = param.value;
    });
    this.ref.close(this.paramsOutput);
  }
}
