<nb-select
  selected="all"
  size="small"
  fullWidth
  [placeholder]="placeHolder"
  (selectedChange)="objectSelectedChange($event)"
  [(selected)]="value"
>
  <nb-option *ngFor="let sentiment of listSentiment" [value]="sentiment.name">{{
    sentiment.name
  }}</nb-option>
</nb-select>
