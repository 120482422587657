import { Component, OnInit } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { ResponseStatus } from "../../../../@core/model/response-status";

@Component({
    selector: "ngx-answer-detect-status",
    templateUrl: "./answer-detect-status.component.html",
    styleUrls: ["./answer-detect-status.component.scss"],
  })
  export class AnswerDetectStatusComponent implements OnInit {
    data: ResponseStatus = new ResponseStatus();
    listStatus: any;
    item: any;
    isUpdate: boolean = false;
    updateData = null;
    constructor(private ref: NbDialogRef<AnswerDetectStatusComponent>) {}
  
    ngOnInit(): void {
      this.listStatus = [
        "MACHINE",
        "HUMAN",
        "NOTSURE",
      ];
      if (Array.isArray(this.item)) {
        for (var i = 0; i < this.item.length; i++) {
          if (this.item[i].value) {
            if (this.isUpdate && this.item[i].value == this.updateData) {
              this.data.status = this.updateData;
            } else {
              var index = this.listStatus.indexOf(this.item[i].value);
              if (index > -1) {
                this.listStatus.splice(index, 1);
              }
            }
          }
        }
      }
  
      if (this.listStatus.length == 1) {
        this.data.status = this.listStatus[0];
        this.dismiss();
      }
    }
  
    ngOnDestroy(): void {
      this.ref.close(this.data);
    }
  
    dismiss() {
      this.ref.close(this.data);
    }
  }
  