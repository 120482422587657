<nb-select
  selected="all"
  size="tiny"
  placeholder="{{ placeholderText }}"
  (selectedChange)="objectSelectedChange($event)"
  [(selected)]="value"
  status="info"
>
  <nb-option value="all">{{ selectAllText }}</nb-option>
  <nb-option *ngFor="let item of listObject" value="{{ item.value }}">{{
    item.display
  }}</nb-option>
</nb-select>
