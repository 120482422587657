import { IApplicationData } from "./common";

export class CustomFunction implements IApplicationData {
  constructor() {
    this.name = "Custom Function";
    this.functionId = 0;
    this.functionPath = "";
    this.attribute = "";
    this.params = "";
    this.language = "";
    this.transition = true;
    this.isRequired = true;
    this.checkFields = false;
  }
  name: string;
  functionId: number;
  functionPath: string;
  attribute: string;
  params: string;
  language: string;
  transition: boolean;
  isRequired: boolean;
  checkFields: boolean;
}
