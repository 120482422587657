import { Component, Input, EventEmitter, Output } from "@angular/core";
@Component({
  selector: "nb-filter-select",
  templateUrl: "./filter-select.component.html",
})
export class FilterSelectComponent {
  @Input() placeholderText: string;
  @Input() selectAllText: string;
  @Input() listObject: any[];
  @Output() valueSelected = new EventEmitter<string>();
  value = "all";
  constructor() {}

  objectSelectedChange(value) {
    this.valueSelected.emit(value);
  }
}
