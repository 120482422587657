import { NgModule } from '@angular/core';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { NbLayoutModule, NbButtonModule, NbIconModule, NbCardModule } from '@nebular/theme';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../@theme/theme.module';
import { PageNotFoundComponent } from './404/page-not-found.component';
import { MiscellaneousComponent } from './error-page.component';
import { MiscellaneousRoutingModule } from './error-page-routing.module';
@NgModule({
    imports: [
        NbLayoutModule,
        FormsModule,
        NbIconModule,
        CommonModule,
        NbButtonModule,
        NbIconModule,
        ThemeModule,
        NbCardModule,
        MiscellaneousRoutingModule
    ],
    declarations: [MiscellaneousComponent, UnauthorizedComponent, PageNotFoundComponent],
})
export class ErrorPageModule {
}
