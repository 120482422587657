<div [formGroup]="parentForm">
  <div class="flex-col">
    <div>
      <div class="flex-title">
        <div class="label-title-wrapper">
          <span class="title">Chatbot Setting</span>
          <nb-icon
            class="hint-icon"
            nbTooltipTrigger="hover"
            nbTooltip="General settings of chatbot plugin."
            nbTooltipPlacement="right"
            nbTooltipStatus="basic"
            icon="question-mark-circle-outline"
          ></nb-icon>
        </div>

        <span class="line"></span>
      </div>
      <div class="overview-area">
        <div class="overview-area-1">
          <div class="box-setting" style="width: 100%">
            <div style="width: 100%">
              <label
                class="form-label label"
                for="input-name-bot"
                style="display: block"
                >Name</label
              >
              <input
                id="input-name-bot"
                type="text"
                nbInput
                fullWidth
                fieldSize="small"
                placeholder="Name"
                formControlName="botName"
                [(ngModel)]="callSetting.botName"
              />
            </div>
          </div>
          <div class="box-setting" style="padding-top: 0; width: 100%">
            <div style="width: 100%">
              <label
                class="form-label label"
                for="input-name-url"
                style="display: block"
                >Logo URL</label
              >
              <input
                type="text"
                nbInput
                fieldSize="small"
                id="input-name-url"
                fullWidth
                formControlName="botLogo"
                [(ngModel)]="callSetting.botLogo"
                placeholder="Logo URL"
              />
            </div>
          </div>
          <div class="box-setting" style="padding-top: 0; width: 100%">
            <div style="width: 100%">
              <label
                class="form-label label"
                for="input-name-greeting"
                style="display: block"
                >Greeting Messages</label
              >
              <textarea
                type="text"
                nbInput
                fieldSize="small"
                id="input-name-greeting"
                style="min-height: 75px; max-height: 125px; margin-bottom: -4px"
                fullWidth
                formControlName="botGreetingMessages"
                [(ngModel)]="callSetting.botGreetingMessages"
                placeholder="Greeting Messages"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="box-setting overview-area-2">
          <div style="width: 100%; max-width: 70rem">
            <label
              class="form-label label"
              for="input-name-url"
              style="display: block"
              >Overview</label
            >
            <nb-card>
              <nb-card-header>
                <nb-user
                  onlyPicture
                  [picture]="callSetting.botLogo || logoDefault"
                >
                </nb-user>
                <span class="botname">{{
                  callSetting.botName || dataService.CallFlow?.name
                }}</span>
              </nb-card-header>
              <nb-card-body>
                <div
                  class="chat-area"
                  *ngIf="
                    callSetting.botGreetingMessages &&
                    callSetting.botGreetingMessages?.trim() != ''
                  "
                >
                  <nb-user
                    style="transform: scale(0.8)"
                    onlyPicture
                    [picture]="callSetting.botLogo || logoDefault"
                  >
                  </nb-user>
                  <div class="chat-box">
                    <span class="chat-name">{{
                      callSetting.botName || dataService.CallFlow?.name
                    }}</span>
                    <span class="chat-text">{{
                      callSetting.botGreetingMessages?.trim()
                    }}</span>
                  </div>
                </div>
                <div class="chat-area chat-area-user">
                  <div class="chat-box chat-box-user">
                    <span class="chat-name">You</span>
                    <span class="chat-text-user"
                      >I want to make an appointment today</span
                    >
                  </div>
                </div>
                <div class="chat-area">
                  <nb-user
                    style="transform: scale(0.8)"
                    onlyPicture
                    [picture]="callSetting.botLogo || logoDefault"
                  >
                  </nb-user>
                  <div class="chat-box">
                    <span class="chat-name">{{
                      callSetting.botName || dataService.CallFlow?.name
                    }}</span>
                    <span class="chat-text"
                      >Certainly, I'm ready to help you schedule an appointment
                      today. What time are you looking for?</span
                    >
                  </div>
                </div>
              </nb-card-body>
            </nb-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
