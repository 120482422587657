import { Component, HostListener, OnInit } from "@angular/core";
import { NbWindowRef } from "@nebular/theme";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { TraceLogData } from "../../../../@core/model/trace-log";
import { DataService } from "../../../../@core/utils/data.service";
import { UntypedFormGroup } from "@angular/forms";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";

@Component({
  selector: "ngx-trace-log",
  templateUrl: "./trace-log.component.html",
  styleUrls: ["./trace-log.component.scss"],
})
export class TraceLogComponent implements OnInit {
  data: TraceLogData = new TraceLogData();
  cell: MxCell;
  graph: MxGraph;
  attributes: any = this.dataService.ivrAttribute;
  ivrFunction: any = this.dataService.ivrFunction;
  mentionConfig = {
    mentions: [
      {
        items: this.attributes,
        triggerChar: "{",
        labelKey: "value",
        disableSort: true,
        mentionSelect: (item) => {
          return "{" + item.value + "}";
        },
      },
      {
        items: this.ivrFunction,
        triggerChar: ">",
        labelKey: "value",
        disableSort: true,
        mentionSelect: (item) => {
          return ">" + item.value;
        },
      },
    ],
  };
  cancelAction = false;
  formGroup: UntypedFormGroup;
  types = [
    { name: "Info", value: "info" },
    { name: "Debug", value: "debug" },
    { name: "Error", value: "error" },
  ];
  constructor(
    protected windowRef: NbWindowRef,
    public dataService: DataService,
    private formBuilder: RxFormBuilder,
    private graphService: GraphHandlerService
  ) {
    this.createForm();
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      content: ["", [RxwebValidators.required(), noWhitespaceValidator]],
    });
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  ngOnInit() {
    for (let [key, value] of Object.entries(this.data)) {
      this.data[key] = this.cell.getAttribute(key);
    }
    if (!this.data.type) {
      this.data.type = "info";
    }

    this.windowRef.onClose.subscribe(() => this.submit());
  }
  submit() {
    if (!this.cancelAction) {
      for (let [key, value] of Object.entries(this.data)) {
        if (typeof this.data[key] == "string") {
          value = value?.trim();
        }
        this.cell.setAttribute(key, value || "");
      }
      this.windowRef.close();
      const tracelogCell = this.graph.getModel().getCell(this.cell.getId());
      const fieldsRequired = [this.data.content.trim()];
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        tracelogCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }
  onChangeContent() {
    const textArea = document.getElementById("content-log");
    if (textArea) {
      textArea.style.height = "auto";
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  }
  drop($event) {
    this.data.content += "{" + $event.dataTransfer.getData("text").trim() + "}";
    $event.preventDefault();
  }
}
