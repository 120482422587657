import { IApplicationData } from "./common";
export class RedirectCallflow implements IApplicationData {
  constructor() {
    this.name = "Subflow";
    this.callFlowId = 0;
    this.inputOption = "[]";
    this.outputOption = "[]";
    this.flowName = "";
    this.variables = "";
    this.isRequired = true;
    this.checkFields = false;
  }
  callFlowId: number;
  name: string;
  variables: string;
  inputOption: string;
  outputOption: string;
  flowName: string;
  isRequired: boolean;
  checkFields: boolean;
}
