import { Component, OnInit, Directive } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";

@Component({
  selector: "app-play-audio-file",
  templateUrl: "./play-audio-file.component.html",
})
export class PlayAudioFileComponent implements OnInit {
  title: string;
  audioUrl: any;

  constructor(protected ref: NbDialogRef<PlayAudioFileComponent>) {}

  ngOnInit(): void {}

  dismiss() {
    this.ref.close();
  }

  onSubmit() {
    this.ref.close();
  }
}
