import { IApplicationData } from "./common";
export class CustomEvent implements IApplicationData {
  constructor() {
    this.name = "Custom Event";
    this.attribute = "";
    this.multipleCase = true;
    this.isRequired = true;
    this.checkFields = false;
  }
  name: string;
  attribute: string;
  multipleCase: boolean;
  isRequired: boolean;
  checkFields: boolean;
}
