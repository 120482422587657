import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    if(/^\d+$/.test(value) && value.length >= 10)
    {
      const areaCode = value.slice(0, 3);
      const prefix = value.slice(3, 6);
      const lineNumber = value.slice(6, 10);
      const other = value.slice(10);
  
      return `(${areaCode}) ${prefix}-${lineNumber}${other}`;
    }

    return value;
  }
}
