import { IApplicationData } from "./common";
export class TraceLogData implements IApplicationData {
  constructor() {
    this.name = "Trace Log";
    this.content = "";
    this.type = "info";
    this.isRequired = true;
    this.checkFields = false;
  }
  name: string;
  content: string;
  type: string;
  isRequired: boolean;
  checkFields: boolean;
}
