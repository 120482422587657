import { IApplicationData } from "./common";
export class CMS implements IApplicationData {
  constructor() {
    this.name = "Data Storages";
    this.method = "GET";
    this.attribute = "";
    this.sortBy = "created";
    this.orderBy = "ascending";
    this.queryLimit = 1;
    this.dataCMS = "[]";
    this.conditions = "[]";
    this.conditionsType = "all";
    this.updateFields = "[]";
    this.cmsId = 0;
    this.cmsName = "";
    this.transition = true;
    this.isRequired = true;
    this.checkFields = false;
  }
  name: string;
  method: string;
  attribute: string;
  conditions: string;
  queryLimit: number;
  sortBy: string;
  orderBy: string;
  dataCMS: string;
  updateFields: string;
  conditionsType: string;
  cmsId: number;
  cmsName: string;
  transition: boolean;
  isRequired: boolean;
  checkFields: boolean;
}
