<form [formGroup]="createSipForm" novalidate (submit)="onSubmit()">
  <nb-card status="basic">
    <nb-card-header class="infor-header">
      Create sip account
    </nb-card-header>
    <nb-card-body>
      <div class="mb-2">
        <label for="input-username" class="form-label label">Username</label>
        <input  type="text" nbInput formControlName="username" 
          maxlength="100" (input)="checkUsername($event.target.value)"
          [status]="(createSipForm.controls['username'].invalid && (createSipForm.controls['username'].dirty || createSipForm.controls['username'].touched))  ? 'danger' : 'basic'"
          fullWidth id="input-username" placeholder="Username" />
        <ng-container
          *ngIf="createSipForm.controls['username'].invalid && (createSipForm.controls['username'].dirty || createSipForm.controls['username'].touched)">
          <p class="caption status-danger" *ngIf="createSipForm.controls['username'].errors.required">
            Username is required.
          </p>
          <p class="caption status-danger" *ngIf="createSipForm.controls['username'].errors.isExist">Username already exist.</p>
          <p class="caption status-danger"
          *ngFor="let errorMessage of createSipForm.controls.username['errorMessages']">
          {{errorMessage}}
        </p>
        </ng-container>
      </div>
  
      <div class="mb-2">
        <label for="input-password" class="label form-label">Password</label>
        <input type="password" nbInput formControlName="password" maxlength="15"
          [status]="(createSipForm.controls['password'].invalid && (createSipForm.controls['password'].dirty || createSipForm.controls['password'].touched))  ? 'danger' : 'basic'"
          fullWidth id="input-password" placeholder="Password" />
        <ng-container
          *ngIf="createSipForm.controls['password'].invalid && (createSipForm.controls['password'].dirty || createSipForm.controls['password'].touched)">
          <p class="caption status-danger" *ngIf="createSipForm.controls['password'].errors.required">
            Password is required.
          </p>
          <p class="caption status-danger"
            *ngFor="let errorMessage of createSipForm.controls.password['errorMessages']">
            {{errorMessage}}
          </p>
        </ng-container>
      </div>
      <div class="mb-2">
        <label for="input-extension" class="form-label label">Extension</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">{{companyId}}-</span>
          </div>
          <input style="margin-left: 2px;" type="text" nbInput formControlName="extension" maxlength="4"
           (input)="checkExtension($event.target.value)"
          [status]="(createSipForm.controls['extension'].invalid && (createSipForm.controls['extension'].dirty || createSipForm.controls['extension'].touched))  ? 'danger' : 'basic'"
           id="input-extension" placeholder="Extension" />
          </div>
        <ng-container
          *ngIf="createSipForm.controls['extension'].invalid && (createSipForm.controls['extension'].dirty || createSipForm.controls['extension'].touched)">
          <p class="caption status-danger" *ngIf="createSipForm.controls['extension'].errors.required">
            Extension is required.
          </p>
          <p class="caption status-danger" *ngIf="createSipForm.controls['extension'].errors.isExist">Extension already exist.</p>
          <p class="caption status-danger"
            *ngFor="let errorMessage of createSipForm.controls.extension['errorMessages']">
            {{errorMessage}}
          </p>
        </ng-container>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button [disabled]="!createSipForm.valid" type="submit" status="primary" class="ms-2 float-end" size="small"
      nbButton> <nb-icon icon="save-outline"></nb-icon>Save</button>
    </nb-card-footer>
  </nb-card>
</form>
