import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import introJs from "intro.js";
import { SignalRService } from "./signalR.service";
import { EventBusService } from "./eventbus.service";
import { DataService } from "./data.service";
import { CallFlowDto } from "../../System-api";
import { MxGraph, MxCell } from "../model/mxgraph";
import { EventData } from "../../shared/model/eventdata";

@Injectable({
  providedIn: "root",
})
export class InstructionService {
  public introJS;
  private scriptIntro;
  private stepCurrent: number;
  constructor(
    private route: Router,
    private dataService: DataService,
    private signalRService: SignalRService,
    private eventBusService: EventBusService
  ) {
    this.introJS = introJs();
    this.initStep();
    this.onListenEvent();
    this.introJS.setOption("showStepNumbers", false);
    this.introJS.setOption("showBullets", false);
    this.introJS.setOption("showProgress", true);
    this.introJS.setOption("hidePrev", true);
    this.introJS.setOption("hideNext", true);
    this.introJS.setOption("keyboardNavigation", false);
    this.introJS.setOption("exitOnEsc", false);
    this.introJS.setOption("exitOnOverlayClick", false);
  }
  onListenEvent() {
    this.introJS.onbeforechange((element) => {
      // this.hiddenButton();
      let step = this.introJS._currentStep;

      this.stepCurrent = step;
      var stepRoute = this.scriptIntro[step].onpage;
      this.route.navigate([stepRoute]);
    });
    this.introJS.onskip((element) => {
      this.stepCurrent = 0;
      this.eventBusService.emit(new EventData("skipIntro", true));
    });
  }

  hiddenButton() {
    let prev = document.getElementsByClassName(
      "introjs-prevbutton"
    )[0] as HTMLElement;
    let next = document.getElementsByClassName(
      "introjs-nextbutton"
    )[0] as HTMLElement;
    let skip = document.getElementsByClassName("introjs-skipbutton")[0] as any;

    if (prev != null) {
      prev.style.visibility = "hidden";
      next.style.visibility = "hidden";
      skip.style.float = "left";
    }
  }
  startIntro() {
    this.signalRService.userMakeIntroCall.subscribe((rs) => {
      if (this.stepCurrent == 0) {
        this.introJS.nextStep();
      }
      if (this.stepCurrent == 2) {
        this.introJS.nextStep();
      }
    });

    this.introJS.start();
    // this.hiddenButton();
    this.eventBusService.on("eventSelectCallfow", (data: boolean) => {
      if (this.stepCurrent == 1 && data) {
        this.introJS.exit();
      }
    });

    this.eventBusService.on("chooseCallFlow", (data: number) => {
      if (this.stepCurrent == 1 && data) {
        this.dataService.CallFlow = new CallFlowDto({ id: data });
        this.introJS.start().goToStep(3);
      }
    });

    this.eventBusService.on("getMxGraph", (graph: MxGraph) => {
      if (this.stepCurrent == 3 && graph != null) {
        var audioAppSample = graph.view.canvas.querySelector(
          '[*|href="' + location.origin + '"]'
        ).parentElement as HTMLElement;
        audioAppSample.classList.add("sample-audioapp");

        this.introJS.start().goToStep(4);
      }
    });

    this.eventBusService.on("clickConfigApp", (cell: MxCell) => {
      if (this.stepCurrent == 3 && cell != null) {
        this.introJS.exit();
        setTimeout(() => {
          this.introJS.start().goToStep(5);
          let configForm = document.getElementsByClassName(
            "introjs-fixParent"
          )[0] as HTMLElement;
          configForm.style.setProperty("z-index", "9999999", "important");
        }, 500);
      }
    });

    this.eventBusService.on("changeAudio", (data: boolean) => {
      if (this.stepCurrent == 4 && data) {
        this.introJS.nextStep();
      }
    });

    this.eventBusService.on("clickSaveCallFlow", (data: boolean) => {
      if (this.stepCurrent == 5 && data) {
        this.stepCurrent = 6;
        this.route.navigate([this.scriptIntro[6].onpage]);
      }
    });

    this.eventBusService.on("inListCallflow", (data: boolean) => {
      if (this.stepCurrent == 6 && data) {
        setTimeout(() => {
          this.introJS.start().goToStep(7);
        }, 1000);
      }
    });
  }

  initStep() {
    this.scriptIntro = [
      {
        element: "#label-number",
        intro:
          "Welcome to your call center, This is phone number of your call center. Make a test call.",
        onpage: "/portal/number/mynumber",
        position: "bottom",
      },
      {
        element: "#select-callflow",
        intro:
          "That is just a greeting, try assigning an existing flow to this phone number.",
        onpage: "/portal/number/mynumber",
        position: "bottom",
      },
      {
        element: "#label-number",
        intro: "Now try calling again, you will be surprised",
        onpage: "/portal/number/mynumber",
      },
      {
        element: ".sample-audioapp",
        intro:
          "That is right, this is the flow you have just heard, each one will be called an application, try clicking on play audio application.",
        onpage: "/callflow-design",
      },
      {
        element: "nb-window",
        intro:
          "Each application will have a corresponding window config. In this example, try selecting the audio you want to use.",
        onpage: "/callflow-design",
      },
      {
        element: "#save-change",
        intro: "Save your change",
        onpage: "/callflow-design",
      },
      {
        element: "#label-number",
        intro: "Call again and see your achievements.",
        onpage: "/portal/callflows",
      },
    ];

    this.introJS.setOptions({
      steps: this.scriptIntro,
    });
  }
}
