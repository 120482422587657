import { IApplicationData } from "./common";
export class TextToSpeech implements IApplicationData {
  constructor() {
    this.name = "Text to speech";
    this.text = "[]";
    this.textChatBot = "";
  }
  name: string;
  text: string;
  textChatBot: string;
}
