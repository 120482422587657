<div class="action-config-wrapper">
  <div class="config-action">
    <div>
      <nb-radio-group [(ngModel)]="data.option" class="choice-option mb-2">
        <nb-radio value="listlang">From List Language</nb-radio>
        <nb-radio value="variable">From Variable</nb-radio>
      </nb-radio-group>

      <div *ngIf="data.option == 'variable'">
        <div class="form-label d-flex" style="align-items: center">
          <label class="label required"> Variable </label>
          <nb-icon
            class="ms-1"
            style="color: #929bb3; width: 15px"
            nbTooltip="Using the variable to set the language, the value will be the language code. Ex: en, vi,.."
            nbTooltipPlacement="bottom"
            nbTooltipStatus="basic"
            nbTooltipTrigger="hover"
            icon="question-mark-circle-outline"
          >
          </nb-icon>
        </div>
        <ngx-input-value-static
          selectAttribute="true"
          [propertyInput]="data.attribute"
          (propertyEmit)="getVariable($event)"
          [errorMessage]="errorMessage"
        >
        </ngx-input-value-static>
      </div>

      <nb-select
        *ngIf="data.option == 'listlang'"
        fullWidth
        placeholder="Select Language"
        [(ngModel)]="data.language"
        size="small"
      >
        <nb-option *ngFor="let item of listlanguage" [value]="item.language"
          >{{ item.name }}
        </nb-option>
      </nb-select>
    </div>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
