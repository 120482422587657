<nb-card>
  <nb-card-header>
    <h6>Case response</h6>
  </nb-card-header>
  <nb-card-body>
    <div class="row m-2">
      <div class="col-12">
        <div class="mb-2">
          <label class="form-label label">Method</label>
          <nb-select [(ngModel)]="data.method" fullWidth placeholder="Select Method" status="info">
            <nb-option value="Press">Key</nb-option>
            <nb-option value="Say">Say</nb-option>
            <nb-option value="Press or Say">Both</nb-option>
          </nb-select>
        </div>
        <div class="mb-2">
          <label for="name" class="form-label label">User Response</label>
          <input id="name" (drop)="drop($event)" type="text" [(ngModel)]="data.data" nbInput fullWidth
            placeholder="User Response" />
        </div>
      </div>
    </div>
    <button class="float-end" (click)="dismiss()" nbButton size="small" status="primary">Confirm</button>
  </nb-card-body>
</nb-card>