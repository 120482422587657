import { IApplicationData } from "./common";
export class CheckAttribute implements IApplicationData {
  constructor() {
    this.name = "Check Variable";
    this.attribute = "";
    this.multipleCase = true;
    this.transition = true;
    this.isRequired = true;
    this.checkFields = false;
  }
  name: string;
  attribute: string;
  multipleCase: boolean;
  transition: boolean;
  isRequired: boolean;
  checkFields: boolean;
}
