export const languageCode = [
  {
    name: "Afrikaans (South Africa)",
    code: {
      amazon: "",
      google: "af-ZA",
      azure: "af-ZA",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Albanian (Albania)",
    code: {
      amazon: "",
      google: "",
      azure: "sq-AL",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Amharic (Ethiopia)",
    code: {
      amazon: "",
      google: "",
      azure: "am-ET",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Arabic",
    code: {
      amazon: "arb",
      google: "ar-XA",
      azure: "",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Arabic (Algeria)",
    code: {
      amazon: "",
      google: "",
      azure: "ar-DZ",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Arabic (Bahrain)",
    code: {
      amazon: "",
      google: "",
      azure: "ar-BH",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Arabic (Egypt)",
    code: {
      amazon: "",
      google: "",
      azure: "ar-EG",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Arabic (Iraq)",
    code: {
      amazon: "",
      google: "",
      azure: "ar-IQ",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Arabic (Jordan)",
    code: {
      amazon: "",
      google: "",
      azure: "ar-JO",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Arabic (Kuwait)",
    code: {
      amazon: "",
      google: "",
      azure: "ar-KW",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Arabic (Lebanon)",
    code: {
      amazon: "",
      google: "",
      azure: "ar-LB",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Arabic (Libya)",
    code: {
      amazon: "",
      google: "",
      azure: "ar-LY",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Arabic (Morocco)",
    code: {
      amazon: "",
      google: "",
      azure: "ar-MA",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Arabic (Oman)",
    code: {
      amazon: "",
      google: "",
      azure: "ar-OM",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Arabic (Qatar)",
    code: {
      amazon: "",
      google: "",
      azure: "ar-QA",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Arabic (Saudi Arabia)",
    code: {
      amazon: "",
      google: "",
      azure: "ar-SA",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Arabic (Syria)",
    code: {
      amazon: "",
      google: "",
      azure: "ar-SY",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Arabic (Tunisia)",
    code: {
      amazon: "",
      google: "",
      azure: "ar-TN",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Arabic (United Arab Emirates)",
    code: {
      amazon: "",
      google: "",
      azure: "ar-AE",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Arabic (Yemen)",
    code: {
      amazon: "",
      google: "",
      azure: "ar-YE",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Armenian (Armenia)",
    code: {
      amazon: "",
      google: "",
      azure: "hy-AM",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Azerbaijani (Latin, Azerbaijan)",
    code: {
      amazon: "",
      google: "",
      azure: "az-AZ",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Bangla (Bangladesh)",
    code: {
      amazon: "",
      google: "",
      azure: "bn-BD",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Basque",
    code: {
      amazon: "",
      google: "eu-ES",
      azure: "eu-ES",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Bengali (India)",
    code: {
      amazon: "",
      google: "bn-IN",
      azure: "bn-IN",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Bosnian (Bosnia and Herzegovina)",
    code: {
      amazon: "",
      google: "",
      azure: "bs-BA",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Bulgarian (Bulgaria)",
    code: {
      amazon: "",
      google: "bg-BG",
      azure: "bg-BG",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Burmese (Myanmar)",
    code: {
      amazon: "",
      google: "",
      azure: "my-MM",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Catalan (Spain)",
    code: {
      amazon: "",
      google: "ca-ES",
      azure: "ca-ES",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Chinese (Cantonese, Simplified)",
    code: {
      amazon: "",
      google: "",
      azure: "yue-CN",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Chinese (Cantonese, Traditional)",
    code: {
      amazon: "",
      google: "",
      azure: "zh-HK",
      primas: "",
      soundhound: "zh",
    },
  },
  {
    name: "Chinese (Hong Kong)",
    code: {
      amazon: "",
      google: "yue-HK",
      azure: "",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Chinese (Jilu Mandarin, Simplified)",
    code: {
      amazon: "",
      google: "",
      azure: "zh-CN-shandong",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Chinese (Mandarin, Simplified)",
    code: {
      amazon: "",
      google: "",
      azure: "zh-CN",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Chinese (Northeastern Mandarin, Simplified)",
    code: {
      amazon: "",
      google: "",
      azure: "zh-CN-liaoning",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Chinese (Southwestern Mandarin, Simplified)",
    code: {
      amazon: "",
      google: "",
      azure: "zh-CN-sichuan",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Chinese (Taiwanese Mandarin, Traditional)",
    code: {
      amazon: "",
      google: "",
      azure: "zh-TW",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Chinese (Wu, Simplified)",
    code: {
      amazon: "",
      google: "",
      azure: "wuu-CN",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Chinese (Zhongyuan Mandarin Henan, Simplified)",
    code: {
      amazon: "",
      google: "",
      azure: "zh-CN-henan",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Chinese (Zhongyuan Mandarin Shaanxi, Simplified)",
    code: {
      amazon: "",
      google: "",
      azure: "zh-CN-shaanxi",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Chinese Traditional",
    code: {
      amazon: "cmn-CN",
      google: "",
      azure: "",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Croatian (Croatia)",
    code: {
      amazon: "",
      google: "",
      azure: "hr-HR",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Czech (Czechia)",
    code: {
      amazon: "",
      google: "cs-CZ",
      azure: "cs-CZ",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Danish (Denmark)",
    code: {
      amazon: "da-DK",
      google: "da-DK",
      azure: "da-DK",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Dutch (Belgium)",
    code: {
      amazon: "",
      google: "nl-BE",
      azure: "nl-BE",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Dutch (Netherlands)",
    code: {
      amazon: "nl-NL",
      google: "nl-NL",
      azure: "nl-NL",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "English (Australia)",
    code: {
      amazon: "en-AU",
      google: "en-AU",
      azure: "en-AU",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "English (Canada)",
    code: {
      amazon: "",
      google: "",
      azure: "en-CA",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "English (Hong Kong SAR)",
    code: {
      amazon: "",
      google: "",
      azure: "en-HK",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "English (India)",
    code: {
      amazon: "en-IN",
      google: "en-IN",
      azure: "en-IN",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "English (Ireland)",
    code: {
      amazon: "",
      google: "",
      azure: "en-IE",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "English (Kenya)",
    code: {
      amazon: "",
      google: "",
      azure: "en-KE",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "English (New Zealand)",
    code: {
      amazon: "",
      google: "",
      azure: "en-NZ",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "English (Nigeria)",
    code: {
      amazon: "",
      google: "",
      azure: "en-NG",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "English (Philippines)",
    code: {
      amazon: "",
      google: "",
      azure: "en-PH",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "English (Singapore)",
    code: {
      amazon: "",
      google: "",
      azure: "en-SG",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "English (South Africa)",
    code: {
      amazon: "",
      google: "",
      azure: "en-ZA",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "English (Tanzania)",
    code: {
      amazon: "",
      google: "",
      azure: "en-TZ",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "English (United Kingdom)",
    code: {
      amazon: "en-GB",
      google: "en-GB",
      azure: "en-GB",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "English (United States)",
    code: {
      amazon: "en-US",
      google: "en-US",
      azure: "en-US",
      primas: "en-US",
      soundhound: "en",
    },
  },
  {
    name: "English, British",
    code: {
      amazon: "en-GB-WLS",
      google: "",
      azure: "",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Estonian (Estonia)",
    code: {
      amazon: "",
      google: "",
      azure: "et-EE",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Filipino (Philippines)",
    code: {
      amazon: "",
      google: "fil-PH",
      azure: "fil-PH",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Finnish (Finland)",
    code: {
      amazon: "",
      google: "fi-FI",
      azure: "fi-FI",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "French (Belgium)",
    code: {
      amazon: "",
      google: "",
      azure: "fr-BE",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "French (Canada)",
    code: {
      amazon: "fr-CA",
      google: "fr-CA",
      azure: "fr-CA",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "French (France)",
    code: {
      amazon: "fr-FR",
      google: "fr-FR",
      azure: "fr-FR",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "French (Switzerland)",
    code: {
      amazon: "",
      google: "",
      azure: "fr-CH",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Galician",
    code: {
      amazon: "",
      google: "gl-ES",
      azure: "gl-ES",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Georgian (Georgia)",
    code: {
      amazon: "",
      google: "",
      azure: "ka-GE",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "German (Austria)",
    code: {
      amazon: "de-AT",
      google: "",
      azure: "de-AT",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "German (Germany)",
    code: {
      amazon: "de-DE",
      google: "de-DE",
      azure: "de-DE",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "German (Switzerland)",
    code: {
      amazon: "",
      google: "",
      azure: "de-CH",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Greek (Greece)",
    code: {
      amazon: "",
      google: "el-GR",
      azure: "el-GR",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Gujarati (India)",
    code: {
      amazon: "",
      google: "gu-IN",
      azure: "gu-IN",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Hebrew (Israel)",
    code: {
      amazon: "",
      google: "he-IL",
      azure: "he-IL",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Hindi (India)",
    code: {
      amazon: "hi-IN",
      google: "hi-IN",
      azure: "hi-IN",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Hungarian (Hungary)",
    code: {
      amazon: "",
      google: "hu-HU",
      azure: "hu-HU",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Icelandic (Iceland)",
    code: {
      amazon: "is-IS",
      google: "is-IS",
      azure: "is-IS",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Indonesian (Indonesia)",
    code: {
      amazon: "",
      google: "id-ID",
      azure: "id-ID",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Irish (Ireland)",
    code: {
      amazon: "",
      google: "",
      azure: "ga-IE",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Italian (Italy)",
    code: {
      amazon: "it-IT",
      google: "it-IT",
      azure: "it-IT",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Japanese (Japan)",
    code: {
      amazon: "ja-JP",
      google: "ja-JP",
      azure: "ja-JP",
      primas: "",
      soundhound: "ja",
    },
  },
  {
    name: "Javanese (Latin, Indonesia)",
    code: {
      amazon: "",
      google: "",
      azure: "jv-ID",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Kannada (India)",
    code: {
      amazon: "",
      google: "kn-IN",
      azure: "kn-IN",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Kazakh (Kazakhstan)",
    code: {
      amazon: "",
      google: "",
      azure: "kk-KZ",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Khmer (Cambodia)",
    code: {
      amazon: "",
      google: "",
      azure: "km-KH",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Korean (Korea)",
    code: {
      amazon: "ko-KR",
      google: "ko-KR",
      azure: "ko-KR",
      primas: "",
      soundhound: "ko",
    },
  },
  {
    name: "Lao (Laos)",
    code: {
      amazon: "",
      google: "",
      azure: "lo-LA",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Latvian (Latvia)",
    code: {
      amazon: "",
      google: "lv-LV",
      azure: "lv-LV",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Lithuanian (Lithuania)",
    code: {
      amazon: "",
      google: "lt-LT",
      azure: "lt-LT",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Macedonian (North Macedonia)",
    code: {
      amazon: "",
      google: "",
      azure: "mk-MK",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Malay (Malaysia)",
    code: {
      amazon: "",
      google: "ms-MY",
      azure: "ms-MY",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Malayalam (India)",
    code: {
      amazon: "",
      google: "ml-IN",
      azure: "ml-IN",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Maltese (Malta)",
    code: {
      amazon: "",
      google: "",
      azure: "mt-MT",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Mandarin Chinese",
    code: {
      amazon: "",
      google: "cmn-CN",
      azure: "",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Marathi (India)",
    code: {
      amazon: "",
      google: "mr-IN",
      azure: "mr-IN",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Mongolian",
    code: {
      amazon: "cmn-CN",
      google: "",
      azure: "",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Mongolian (Mongolia)",
    code: {
      amazon: "",
      google: "",
      azure: "mn-MN",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Nepali (Nepal)",
    code: {
      amazon: "",
      google: "",
      azure: "ne-NP",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Norwegian (Norway)",
    code: {
      amazon: "",
      google: "no-NO",
      azure: "",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Norwegian Bokmål (Norway)",
    code: {
      amazon: "",
      google: "",
      azure: "nb-NO",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Pashto (Afghanistan)",
    code: {
      amazon: "",
      google: "",
      azure: "ps-AF",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Persian (Iran)",
    code: {
      amazon: "",
      google: "",
      azure: "fa-IR",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Polish (Poland)",
    code: {
      amazon: "pl-PL",
      google: "pl-PL",
      azure: "pl-PL",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Portuguese (Brazil)",
    code: {
      amazon: "pt-BR",
      google: "pt-BR",
      azure: "pt-BR",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Portuguese (Portugal)",
    code: {
      amazon: "pt-PT",
      google: "pt-PT",
      azure: "pt-PT",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Punjabi (India)",
    code: {
      amazon: "",
      google: "pa-IN",
      azure: "",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Romanian (Romania)",
    code: {
      amazon: "ro-RO",
      google: "ro-RO",
      azure: "ro-RO",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Russian (Russia)",
    code: {
      amazon: "ru-RU",
      google: "ru-RU",
      azure: "ru-RU",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Serbian (Cyrillic, Serbia)",
    code: {
      amazon: "",
      google: "sr-RS",
      azure: "sr-RS",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Sinhala (Sri Lanka)",
    code: {
      amazon: "",
      google: "",
      azure: "si-LK",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Slovak (Slovakia)",
    code: {
      amazon: "",
      google: "sk-SK",
      azure: "sk-SK",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Slovenian (Slovenia)",
    code: {
      amazon: "",
      google: "",
      azure: "sl-SI",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Somali (Somalia)",
    code: {
      amazon: "",
      google: "",
      azure: "so-SO",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Spanish (Argentina)",
    code: {
      amazon: "",
      google: "",
      azure: "es-AR",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Spanish (Bolivia)",
    code: {
      amazon: "",
      google: "",
      azure: "es-BO",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Spanish (Chile)",
    code: {
      amazon: "",
      google: "",
      azure: "es-CL",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Spanish (Colombia)",
    code: {
      amazon: "",
      google: "",
      azure: "es-CO",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Spanish (Costa Rica)",
    code: {
      amazon: "",
      google: "",
      azure: "es-CR",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Spanish (Cuba)",
    code: {
      amazon: "",
      google: "",
      azure: "es-CU",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Spanish (Dominican Republic)",
    code: {
      amazon: "",
      google: "",
      azure: "es-DO",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Spanish (Ecuador)",
    code: {
      amazon: "",
      google: "",
      azure: "es-EC",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Spanish (El Salvador)",
    code: {
      amazon: "",
      google: "",
      azure: "es-SV",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Spanish (Equatorial Guinea)",
    code: {
      amazon: "",
      google: "",
      azure: "es-GQ",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Spanish (Guatemala)",
    code: {
      amazon: "",
      google: "",
      azure: "es-GT",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Spanish (Honduras)",
    code: {
      amazon: "",
      google: "",
      azure: "es-HN",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Spanish (Mexico)",
    code: {
      amazon: "es-MX",
      google: "",
      azure: "es-MX",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Spanish (Nicaragua)",
    code: {
      amazon: "",
      google: "",
      azure: "es-NI",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Spanish (Panama)",
    code: {
      amazon: "",
      google: "",
      azure: "es-PA",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Spanish (Paraguay)",
    code: {
      amazon: "",
      google: "",
      azure: "es-PY",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Spanish (Peru)",
    code: {
      amazon: "",
      google: "",
      azure: "es-PE",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Spanish (Puerto Rico)",
    code: {
      amazon: "",
      google: "",
      azure: "es-PR",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Spanish (Spain)",
    code: {
      amazon: "es-ES",
      google: "es-ES",
      azure: "es-ES",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Spanish (United States)",
    code: {
      amazon: "es-US",
      google: "es-US",
      azure: "es-US",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Spanish (Uruguay)",
    code: {
      amazon: "",
      google: "",
      azure: "es-UY",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Spanish (Venezuela)",
    code: {
      amazon: "",
      google: "",
      azure: "es-VE",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Sundanese (Indonesia)",
    code: {
      amazon: "",
      google: "",
      azure: "su-ID",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Swahili (Kenya)",
    code: {
      amazon: "",
      google: "",
      azure: "sw-KE",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Swahili (Tanzania)",
    code: {
      amazon: "",
      google: "",
      azure: "sw-TZ",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Swedish (Sweden)",
    code: {
      amazon: "sv-SE",
      google: "sv-SE",
      azure: "sv-SE",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Tamil (India)",
    code: {
      amazon: "",
      google: "ta-IN",
      azure: "ta-IN",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Tamil (Malaysia)",
    code: {
      amazon: "",
      google: "",
      azure: "ta-MY",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Tamil (Singapore)",
    code: {
      amazon: "",
      google: "",
      azure: "ta-SG",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Tamil (Sri Lanka)",
    code: {
      amazon: "",
      google: "",
      azure: "ta-LK",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Telugu (India)",
    code: {
      amazon: "",
      google: "te-IN",
      azure: "te-IN",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Thai (Thailand)",
    code: {
      amazon: "",
      google: "th-TH",
      azure: "th-TH",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Turkish (Turkey)",
    code: {
      amazon: "",
      google: "tr-TR",
      azure: "tr-TR",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Ukrainian (Ukraine)",
    code: {
      amazon: "",
      google: "uk-UA",
      azure: "uk-UA",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Urdu (India)",
    code: {
      amazon: "",
      google: "",
      azure: "ur-IN",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Urdu (Pakistan)",
    code: {
      amazon: "",
      google: "",
      azure: "ur-PK",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Uzbek (Latin, Uzbekistan)",
    code: {
      amazon: "",
      google: "",
      azure: "uz-UZ",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Vietnamese (Vietnam)",
    code: {
      amazon: "",
      google: "vi-VN",
      azure: "vi-VN",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Welsh (United Kingdom)",
    code: {
      amazon: "cy-GB",
      google: "",
      azure: "cy-GB",
      primas: "",
      soundhound: "",
    },
  },
  {
    name: "Zulu (South Africa)",
    code: {
      amazon: "",
      google: "",
      azure: "zu-ZA",
      primas: "",
      soundhound: "",
    },
  },
];
export const languageCodeSource = [
  {
    name: "Language Detection",
    code: {
      amazon: "detect",
      google: "detect",
      azure: "detect",
      primas: "detect",
      soundhound: "detect",
    },
  },
  ...languageCode,
];
