import { Component, Input } from "@angular/core";

@Component({
  selector: "ngx-chat",
  templateUrl: "chat.component.html",
  styleUrls: ["chat.component.scss"],
})
export class ChatComponent {
  @Input() callFlowJson: string = null;
  @Input() callSettings: string = null;
  @Input() attribute: string = null;
  link = "";
  constructor() {}

  ngOnInit() {}

  dismiss() {
    document.getElementById("chat-popup").style.animation = "moveoutcall 0.6s";
    setTimeout(() => {
      document.getElementById("chat-popup").style.animation = "movetocall 0.6s";
    }, 500);
  }

  sendChatFlow(callFlowJson: string, callSettings: string, attribute: string) {}

  sendMessage(event: any) {}
  selectAnswer(message) {}

  ngOnDestroy(): void {
    this.link = "";
  }
}
