import { AgentSummaryVm, QueueSummary } from '../../System-api';

export interface TreeNode<T> {
    data: T;
    expanded?: boolean;
  }
  
  export interface IApplicationData {

  }
  export interface CallingDatasOnDashBoard {
  }

  export class AgentSummaryDashBoard {
    agentSummary: AgentSummaryVm;
    queueSummary: QueueSummary;
  }
