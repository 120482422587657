export const timezoneList = [
  { name: "(GMT-11:00) Pacific/Midway", value: "Pacific/Midway" },
  { name: "(GMT-10:00) Pacific/Honolulu", value: "Pacific/Honolulu" },
  { name: "(GMT-09:00) America/Anchorage", value: "America/Anchorage" },
  { name: "(GMT-08:00) America/Los_Angeles", value: "America/Los_Angeles" },
  { name: "(GMT-07:00) America/Denver", value: "America/Denver" },
  { name: "(GMT-06:00) America/Chicago", value: "America/Chicago" },
  { name: "(GMT-05:00) America/New_York", value: "America/New_York" },
  { name: "(GMT-04:00) America/Caracas", value: "America/Caracas" },
  { name: "(GMT-03:00) America/Buenos_Aires", value: "America/Buenos_Aires" },
  {
    name: "(GMT-02:00) Atlantic/South_Georgia",
    value: "Atlantic/South_Georgia",
  },
  { name: "(GMT-01:00) Atlantic/Azores", value: "Atlantic/Azores" },
  { name: "(GMT+00:00) Africa/Casablanca", value: "Africa/Casablanca" },
  { name: "(GMT+01:00) Europe/Paris", value: "Europe/Paris" },
  { name: "(GMT+02:00) Europe/Istanbul", value: "Europe/Istanbul" },
  { name: "(GMT+03:00) Europe/Moscow", value: "Europe/Moscow" },
  { name: "(GMT+04:00) Asia/Dubai", value: "Asia/Dubai" },
  { name: "(GMT+05:00) Asia/Karachi", value: "Asia/Karachi" },
  { name: "(GMT+06:00) Asia/Almaty", value: "Asia/Almaty" },
  { name: "(GMT+07:00) Asia/Ho_Chi_Minh", value: "Asia/Ho_Chi_Minh" },
  { name: "(GMT+08:00) Asia/Shanghai", value: "Asia/Shanghai" },
  { name: "(GMT+09:00) Asia/Tokyo", value: "Asia/Tokyo" },
  { name: "(GMT+10:00) Australia/Sydney", value: "Australia/Sydney" },
  { name: "(GMT+11:00) Pacific/Guadalcanal", value: "Pacific/Guadalcanal" },
  { name: "(GMT+12:00) Pacific/Fiji", value: "Pacific/Fiji" },
];
