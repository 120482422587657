import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  NbDialogRef,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from "@nebular/theme";
import { EventBusService } from "../../../../../@core/utils/eventbus.service";
import { EventData } from "../../../../../shared/model/eventdata";
import {
  CustomFunctionClient,
  CustomFunctionDto,
  GetSettingValuesCompanyQuery,
  MakeTestCustomFunctionCommand,
  NumberClient,
  NumberVm,
  SettingsClient,
} from "../../../../../System-api";

@Component({
  selector: "test-function",
  templateUrl: "./test-function.component.html",
  styleUrls: ["./test-function.component.scss"],
})
export class TestFunctionComponent implements OnInit, OnDestroy {
  runFunctionResponse: any = {};
  constructor(private ref: NbDialogRef<TestFunctionComponent>) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {}
  closeTerminal() {
    this.ref.close();
  }
}
