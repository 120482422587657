import { CMSClient, GetAllCMSQuery, Page } from "./../../../../System-api";
import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import {
  NbDialogService,
  NbPopoverDirective,
  NbWindowRef,
} from "@nebular/theme";
import { DialogAttributeComponent } from "../../other/dialog-attribute/dialog-attribute.component";
import { Observable, of } from "rxjs";
import { CustomEvent } from "../../../../@core/model/custom-event";
import { DataService } from "../../../../@core/utils/data.service";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";
import { CMS } from "../../../../@core/model/cms";
import { UntypedFormGroup } from "@angular/forms";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";
import { Router } from "@angular/router";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { MatMenuTrigger } from "@angular/material/menu";

@Component({
  selector: "cms-action",
  templateUrl: "./cms-action.component.html",
  styleUrls: ["./cms-action.component.scss"],
})
export class CMSActionComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  listCondition;
  cell: MxCell;
  graph: MxGraph;
  data: CMS = new CMS();
  @ViewChild("autoInput") input;
  cancelAction = false;
  errorMessage: string = "Variable is required.";
  isLoading = false;
  cmsList = [];
  cmsDetails = null;
  formGroup: UntypedFormGroup;
  dataCMS = [];
  icon: string = "plus-outline";
  conditions = [
    { name: "Contains", value: "contains" },
    { name: "Not Contains", value: "not contains" },
    { name: "Equals", value: "equals" },
    { name: "Not Equals", value: "not equals" },
    { name: "Starts With", value: "starts with" },
    { name: "Ends With", value: "ends with" },
    { name: "Null Or Empty", value: "null or empty" },
  ];

  attributes: any = this.dataService.ivrAttribute;
  mentionConfig = {
    items: this.attributes,
    triggerChar: "{",
    labelKey: "value",
    disableSort: true,
    mentionSelect: (item) => {
      return "{" + item.value + "}";
    },
  };
  conditionsArray = [];
  updateArray = [];
  conditionTemp = {
    cmsEntity: null,
    conditions: "equals",
    value: null,
  };
  current = -1;
  isUpdate = false;
  constructor(
    private dialogService: NbDialogService,
    protected windowRef: NbWindowRef,
    public dataService: DataService,
    private graphService: GraphHandlerService,
    private cmsClient: CMSClient,
    private formBuilder: RxFormBuilder,
    private router: Router
  ) {
    this.createForm();
  }

  updateConditionType(type) {
    this.trigger.closeMenu();
    this.data.conditionsType = type;
  }
  handleOnlyIntegerNumber(event) {
    if (event.target?.value != null) {
      var number = event.target.value.toString();
      if (number.match("^[0-9]*$")) {
        this.data[event.target.attributes.id.value] = number;
      }
    }
  }
  dropOptions(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.conditionsArray,
      event.previousIndex,
      event.currentIndex
    );
  }
  addToObject() {
    let dataExist = this.dataCMS.find(
      (x) => x.id == this.conditionTemp.cmsEntity
    );
    if (dataExist) {
      if (this.conditionTemp.conditions == "null or empty") {
        this.conditionTemp.value = null;
      }
      const obj = {
        cmsEntity: dataExist,
        conditions: this.conditionTemp.conditions,
        value: this.conditionTemp.value?.trim(),
      };
      this.conditionsArray.push(obj);
    }
    this.closeModal();
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      cms: ["", [RxwebValidators.required(), noWhitespaceValidator]],
    });
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  handleEdit(id) {
    this.router.navigate([]).then((result) => {
      window.open(`/portal/data-storages/details/${id}`, "_blank");
    });
  }
  getAttribute(event) {
    this.data.attribute = event;
  }
  deleteInList(i) {
    this.conditionsArray.splice(i, 1);
    if (i > 0) {
      this.current = i - 1;
    } else {
      this.current = 0;
    }
  }
  createNew() {
    const newWindow = window.open("/portal/data-storages", "_blank");
    if (newWindow) {
      newWindow.addEventListener("load", () => {
        newWindow.postMessage({ action: "loaded" }, "*");
      });
    }
  }
  setCMS(cms) {
    if (cms?.cmsEntities && cms?.cmsEntities.length > 0) {
      let currentEntity = [];
      cms.cmsEntities.forEach((entity) => {
        var obj = { ...entity };
        obj["value"] = "";
        currentEntity.push(obj);
      });
      this.dataCMS = currentEntity;
      this.data.cmsName = cms.name;
    } else {
      this.dataCMS = [];
      this.data.cmsName = "";
    }
    this.conditionsArray = [];
    this.updateArray = [];
    this.data.sortBy = "created";
  }
  getCMSData() {
    this.isLoading = true;
    this.cmsClient.withEntity().subscribe(
      (rs) => {
        this.isLoading = false;
        this.cmsList = rs;
        for (let [key, value] of Object.entries(this.data)) {
          this.data[key] = this.cell.getAttribute(key);
        }
        if (this.data.cmsId) {
          let dataExist = this.cmsList?.find((x) => x.id == this.data.cmsId);
          if (dataExist) {
            this.data.cmsId =
              Number(this.data.cmsId) == 0 || isNaN(this.data.cmsId)
                ? null
                : Number(this.data.cmsId);
            this.data.name = dataExist.name;
          } else {
            this.resetValue();
          }
        } else {
          this.resetValue();
        }
        this.handleCMSSortBy();
        this.handleCMSData();
        this.handleCMSCondition();
        this.handleCMSUpdate();
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }
  resetValue() {
    this.data.cmsId = null;
    this.data.dataCMS = "[]";
    this.data.updateFields = "[]";
    this.data.conditions = "[]";
    this.data.sortBy = "created";
    this.data.cmsName = "";
  }
  moreUpdate() {
    var object = { uniqueName: "", value: "" };
    this.updateArray.push(object);
  }
  removeItem(i) {
    this.updateArray.splice(i, 1);
  }
  handleCMSUpdate() {
    let updateArray = JSON.parse(this.data.updateFields);
    if (this.data.cmsId && updateArray && updateArray.length > 0) {
      let cms = this.cmsList?.find((x) => x.id == this.data.cmsId);
      if (cms) {
        if (cms?.cmsEntities && cms?.cmsEntities.length > 0) {
          updateArray = updateArray.filter((condition) => {
            let checkExist = cms?.cmsEntities.find(
              (x) => x?.uniqueName == condition?.uniqueName
            );
            if (checkExist) {
              return true;
            } else {
              return false;
            }
          });
          this.updateArray = updateArray;
        } else {
          this.updateArray = [];
        }
      } else {
        this.updateArray = [];
      }
    } else {
      this.updateArray = [];
    }
  }
  handleCMSSortBy() {
    let dataCMS = JSON.parse(this.data.dataCMS);
    if (
      this.data.cmsId &&
      dataCMS &&
      dataCMS.length > 0 &&
      this.data.sortBy != "created"
    ) {
      let cms = this.cmsList?.find((x) => x.id == this.data.cmsId);
      if (cms) {
        if (cms?.cmsEntities && cms?.cmsEntities.length > 0) {
          let checkExist = cms?.cmsEntities.find(
            (x) => x.uniqueName == this.data.sortBy
          );

          if (!checkExist) {
            this.data.sortBy = "created";
          }
        } else {
          this.data.sortBy = "created";
        }
      } else {
        this.data.sortBy = "created";
      }
    } else {
      this.data.sortBy = "created";
    }
  }
  handleCMSCondition() {
    let conditions = JSON.parse(this.data.conditions);
    if (this.data.cmsId && conditions && conditions.length > 0) {
      let cms = this.cmsList?.find((x) => x.id == this.data.cmsId);
      if (cms) {
        if (cms?.cmsEntities && cms?.cmsEntities.length > 0) {
          conditions = conditions.filter((condition) => {
            let checkExist = cms?.cmsEntities.find(
              (x) => x?.id == condition?.cmsEntity?.id
            );
            if (checkExist) {
              condition.cmsEntity = checkExist;
              return true;
            } else {
              return false;
            }
          });

          this.conditionsArray = conditions;
        } else {
          this.conditionsArray = [];
        }
      } else {
        this.conditionsArray = [];
      }
    } else {
      this.conditionsArray = [];
    }
  }
  mentionListOpen(event) {
    const value = event?.target?.value;
    if (value[value.length - 1] == "{") {
      const mentionConfig = document.getElementsByTagName("mention-list");
      const firstMentionList = mentionConfig[0];

      if (firstMentionList) {
        firstMentionList.classList.add("input-value");
      }
    }
  }
  handleCMSData() {
    let dataCMS = JSON.parse(this.data.dataCMS);
    if (this.data.cmsId && dataCMS && dataCMS.length > 0) {
      let cms = this.cmsList?.find((x) => x.id == this.data.cmsId);
      if (cms) {
        if (cms?.cmsEntities && cms?.cmsEntities.length > 0) {
          let currentEntity = [];
          cms.cmsEntities.forEach((entity) => {
            var obj = { ...entity };
            let oldData = dataCMS.find((x) => x.id == entity.id);
            if (oldData) {
              obj["value"] = oldData?.value || "";
            } else {
              obj["value"] = "";
            }
            currentEntity.push(obj);
          });
          this.dataCMS = currentEntity;
        } else {
          this.dataCMS = [];
        }
      } else {
        this.dataCMS = [];
      }
    } else {
      this.dataCMS = [];
    }
  }

  showConditions(isUpdate = false, index = -1, item = null) {
    if (this.icon == "plus-outline") {
      this.isUpdate = isUpdate;
      this.conditionTemp = {
        cmsEntity: null,
        conditions: "equals",
        value: null,
      };
      if (isUpdate == true) {
        this.current = index;
        this.conditionTemp = {
          cmsEntity: item.cmsEntity.id,
          conditions: item.conditions,
          value: item.value,
        };
      }
      this.icon = "close-outline";
      this.popover?.show();
    } else {
      this.isUpdate = false;
      this.current = -1;
      this.icon = "plus-outline";
      this.popover?.hide();
    }
  }
  updateToObject() {
    let dataExist = this.dataCMS.find(
      (x) => x.id == this.conditionTemp.cmsEntity
    );
    if (dataExist) {
      if (this.conditionTemp.conditions == "null or empty") {
        this.conditionTemp.value = null;
      }
      this.conditionsArray[this.current].cmsEntity = dataExist;
      this.conditionsArray[this.current].conditions =
        this.conditionTemp.conditions;
      this.conditionsArray[this.current].value =
        this.conditionTemp.value?.trim();
    }

    this.closeModal();
  }
  closeModal() {
    this.icon = "plus-outline";
    this.popover?.hide();
    this.isUpdate = false;
    this.current = -1;
  }
  ngOnInit() {
    this.getCMSData();
    this.windowRef.onClose.subscribe(() => this.submit());
  }
  handleTrim() {
    if (this.dataCMS && this.dataCMS.length > 0) {
      this.dataCMS.forEach((element) => {
        element.value = element?.value?.trim();
      });
    }
    if (this.updateArray && this.updateArray.length > 0) {
      this.updateArray.forEach((element) => {
        element.value = element?.value?.trim();
      });
    }
  }
  submit() {
    if (!this.cancelAction) {
      this.handleTrim();
      this.data.dataCMS = JSON.stringify(this.dataCMS);
      this.data.conditions = JSON.stringify(this.conditionsArray);
      this.data.updateFields = JSON.stringify(this.updateArray);
      for (let [key, value] of Object.entries(this.data)) {
        this.cell.setAttribute(key, value || "");
      }
      const subflowCell = this.graph.getModel().getCell(this.cell.getId());
      const fieldsRequired = [this.data.cmsId];
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        subflowCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }
}
