import { Component, OnInit, Directive } from "@angular/core";
import {
  NbDialogRef,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from "@nebular/theme";
import { DataService } from "../../../../@core/utils/data.service";
import { UntypedFormGroup } from "@angular/forms";
import { RxFormBuilder } from "@rxweb/reactive-form-validators";
import { ValidInput } from "../../../../@core/utils/helpers";

@Component({
  selector: "app-dialog-attribute",
  templateUrl: "./dialog-attribute.component.html",
  styleUrls: ["./dialog-attribute.component.scss"],
})
export class DialogAttributeComponent implements OnInit {
  index: number;
  value: string = "";
  content: string = "";
  currentValue = "";
  variableList = [];
  invalidName = false;
  dialogAttributeForm: UntypedFormGroup;

  constructor(
    private dataService: DataService,
    private formBuilder: RxFormBuilder,
    protected ref: NbDialogRef<DialogAttributeComponent>,
    private toastrService: NbToastrService
  ) {}
  routerVariable() {
    window.open("https://docs.primas.net/display/CAL/Variable");
  }
  ngOnInit(): void {
    this.createForm();
    this.variableList = [...this.dataService.ivrAttribute];
    const inputName = document.getElementById("variable-name");
    if (inputName) {
      inputName.focus();
    }
  }

  createForm() {
    this.dialogAttributeForm = this.formBuilder.group({
      name: ["", ValidInput.variable],
      defaultValue: [""],
    });
  }

  onChangeContent() {
    const textArea = document.getElementById("text-value-attribute");
    if (textArea) {
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  }
  checkInvalidName($event) {
    if (this.dialogAttributeForm.valid) {
      const data = this.variableList.find((x) => {
        return x.value == $event && $event != this.currentValue;
      });
      if (data) {
        this.dialogAttributeForm.controls["name"].setErrors({});
        this.dialogAttributeForm.controls["name"].setErrors({
          duplicateName: true,
        });
      }
    }
  }
  dismiss() {
    this.ref.close();
  }
  updateAttribute() {
    if (this.dialogAttributeForm.valid) {
      if (this.index != undefined) {
        this.dataService.ivrAttribute[this.index].value = this.value.trim();
        this.dataService.ivrAttribute[this.index].content = this.content.trim();
        this.dataService.ivrAttributeArray[this.index] = this.value.trim();
        this.showToast(true);
      } else {
        if (this.value.trim() != "") {
          this.dataService.ivrAttribute.push({
            default: 0,
            value: this.value.trim(),
            content: this.content.trim(),
          });
          this.dataService.ivrAttributeArray.push(this.value.trim());
          this.showToast(true);
        }
      }
      this.ref.close(this.value.trim());
    } else {
      this.showToast(false);
    }
  }

  showToast(result) {
    if (result) {
      // this.toastrService.show("Save variable successfully.", `Notification`, {
      //   position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
      //   status: "success",
      // });
    } else {
      this.toastrService.show("Save variable unsuccessfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
}
