import { Component, HostListener, OnInit } from "@angular/core";
import { NbWindowRef } from "@nebular/theme";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { WaitAction } from "../../../../@core/model/wait-action";
import { RxFormBuilder, RxwebValidators } from "@rxweb/reactive-form-validators";
import { UntypedFormGroup } from "@angular/forms";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";

@Component({
  selector: "wait-action",
  templateUrl: "./wait-action.component.html",
  styleUrls: ["./wait-action.component.scss"],
})
export class WaitActionComponent implements OnInit {
  cell: MxCell;
  graph: MxGraph;
  data: WaitAction = new WaitAction();
  cancelAction = false;
  formGroup: UntypedFormGroup;
  constructor(
    protected windowRef: NbWindowRef,
    private formBuilder: RxFormBuilder,
    private graphService: GraphHandlerService,
  ) {
    this.createForm();
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      second: [
            "", 
            [
              RxwebValidators.required(),
              noWhitespaceValidator
            ],
          ],
      });
    }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  ngOnInit() {
    for (let [key, value] of Object.entries(this.data)) {
      this.data[key] = this.cell.getAttribute(key);
    }
    this.windowRef.onClose.subscribe(() => this.submit());
  }

  submit() {
    if (!this.cancelAction) {
      if (this.data.second == "" || !this.data.second ||
      parseFloat(this.data.second) < 0 ||
      !Number.isInteger(parseFloat(this.data.second))
      ) {
        this.data.second = '2';
      }
      
      for (let [key, value] of Object.entries(this.data)) {
        this.cell.setAttribute(key, value || "");
      }
      const waitActionCell = this.graph.getModel().getCell(this.cell.getId());
      const fieldsRequired = [this.data.second];
      const check = this.graphService.checkIsRequired(this.graph, fieldsRequired, waitActionCell);
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }
}
