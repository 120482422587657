import { IApplicationData } from "./common";
export class SayAttribute implements IApplicationData {
  constructor() {
    this.name = "Say Variable";
    this.reader = "";
    this.number = "";
    this.type = "";
    this.audioId = "";
    this.audioPath = "";
    this.isRequired = true;
    this.checkFields = false;
  }
  name: string;
  number: string;
  reader: string;
  type: string;
  audioPath: string;
  audioId: string;
  isRequired: boolean;
  checkFields: boolean;
}
