import { Component, OnInit } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";

@Component({
  selector: "keyboard-shortcuts",
  templateUrl: "./keyboard-shortcuts.component.html",
  styleUrls: ["./keyboard-shortcuts.component.scss"],
})
export class KeyboardShortcutsComponent implements OnInit {
  data = [];
  constructor(protected ref: NbDialogRef<KeyboardShortcutsComponent>) {}

  ngOnInit() {}
  dismiss() {
    this.ref.close();
  }
}
