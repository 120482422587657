import { Component, HostListener, OnInit } from "@angular/core";
import { NbDialogService, NbWindowRef } from "@nebular/theme";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { SetLanguage } from "../../../../@core/model/set-language";
import { DataService } from "../../../../@core/utils/data.service";
import { DialogAttributeComponent } from "../../other/dialog-attribute/dialog-attribute.component";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";
import { FormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";

@Component({
  selector: "ngx-set-language",
  templateUrl: "./set-language.component.html",
  styleUrls: ["./set-language.component.scss"],
})
export class SetLanguageComponent implements OnInit {
  cell: MxCell;
  graph: MxGraph;
  callSetting: any;
  listlanguage: any[];
  selectedlanguage: string;
  data: SetLanguage = new SetLanguage();
  cancelAction = false;
  errorMessage: string = "Variable is required.";
  constructor(
    protected windowRef: NbWindowRef,
    public dataService: DataService,
    private dialogService: NbDialogService,
    private graphService: GraphHandlerService
  ) {}
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  ngOnInit() {
    if (!(this?.callSetting?.languageSetting == undefined)) {
      this.listlanguage =
        JSON.parse(this?.callSetting?.languageSetting) == null
          ? []
          : JSON.parse(this?.callSetting?.languageSetting);
    }
    if (this.listlanguage != undefined && this.listlanguage.length > 0) {
      this.listlanguage = this.listlanguage.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    }
    if (this.cell && this.data) {
      for (let [key, value] of Object.entries(this.data)) {
        this.data[key] = this.cell.getAttribute(key);
      }
      if (this.data.language && this.data.language != "undefined") {
        this.data.language = JSON.parse(this.data.language);
        this.data.language = this.data.language.language;
      } else {
        this.listlanguage.forEach((element) => {
          if (element.defaultUser) {
            this.data.language = element.language;
          }
        });
      }
      this.windowRef.onClose.subscribe(() => this.submit());
    }
  }

  submit() {
    if (!this.cancelAction) {
      this.data.language = JSON.stringify(
        this.listlanguage.find((x) => x.language === this.data.language)
      );
      for (let [key, value] of Object.entries(this.data)) {
        this.cell.setAttribute(key, value || "");
      }
      var fieldsRequired = [];
      const setLangCell = this.graph.getModel().getCell(this.cell.getId());
      if (this.data.option == "variable") {
        fieldsRequired = [this.data.attribute];
      }
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        setLangCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }

  addAttribute() {
    this.dialogService
      .open(DialogAttributeComponent, { autoFocus: false })
      .onClose.subscribe((rs) => {
        if (rs) {
          this.data.attribute = rs;
        }
      });
  }
  getVariable($event) {
    this.data.attribute = $event;
  }
}
