<div [formGroup]="parentForm">
  <div class="flex-col">
    <div>
      <div class="flex-title">
        <div class="label-title-wrapper">
          <span class="title">Translate Setting</span>
          <nb-icon
            class="hint-icon"
            nbTooltipTrigger="hover"
            nbTooltip="Choose the translate service you want to use for your flow."
            nbTooltipPlacement="right"
            nbTooltipStatus="basic"
            icon="question-mark-circle-outline"
          ></nb-icon>
        </div>
        <span class="line"></span>
      </div>
      <div class="box-setting">
        <div>
          <label
            class="form-label label required"
            for="input-voice"
            style="display: block"
            >Service</label
          >
          <nb-select
            placeholder="Select Service"
            size="small"
            formControlName="translateService"
            [(ngModel)]="callSetting.translateService"
            (selectedChange)="handleSelect($event)"
            style="width: 12rem"
          >
            <nb-option *ngFor="let item of data" [value]="item.value">{{
              item.name
            }}</nb-option>
          </nb-select>
        </div>
      </div>
    </div>
    <form>
      <div class="flex-title">
        <span class="title">Translate Testing</span>
        <span class="line"></span>
      </div>
      <div
        class="box-setting"
        style="flex-wrap: nowrap; align-items: start; padding-bottom: 0"
      >
        <div style="margin-bottom: 0.5rem">
          <label class="form-label label" style="display: block">Service</label>
          <div>
            <nb-select
              multiple
              placeholder="Select Service"
              size="small"
              [(ngModel)]="selectedService"
              style="width: 12rem; margin-right: 0.5rem"
            >
              <nb-option *ngFor="let item of data" [value]="item.name">{{
                item.name
              }}</nb-option>
            </nb-select>
            <button
              [nbSpinner]="isLoading"
              nbSpinnerStatus="primary"
              [disabled]="
                selectedService.length == 0 ||
                !languageText.value ||
                !languageSource.value ||
                !textPreview.value ||
                !selectedLanguage ||
                !selectedLanguageSource ||
                isLoading
              "
              nbButton
              type="button"
              size="small"
              status="primary"
              (click)="translate()"
            >
              Translate
            </button>
          </div>
        </div>
      </div>
      <div>
        <label
          class="form-label label"
          style="display: block; margin-top: 0.5rem"
          >Translator</label
        >
      </div>
      <div
        class="box-setting"
        style="
          flex-wrap: nowrap;
          align-items: start;
          padding-bottom: 0;
          padding-top: 0;
          gap: 0.25rem;
        "
      >
        <div style="width: 34rem; margin-bottom: 0.5rem">
          <div class="translate-text-wrapper">
            <div style="margin-bottom: 0.5rem">
              <input
                id="languageSource"
                [formControl]="languageSource"
                type="text"
                nbInput
                fullWidth
                fieldSize="small"
                placeholder="Source Language"
                [nbAutocomplete]="autoNgModel2"
              />
              <nb-autocomplete #autoNgModel2>
                <nb-option
                  *ngFor="let option of filteredLanguageSource$ | async"
                  [value]="option.name"
                  [ngClass]="{
                    'bg-active': option.name == languageSource?.value
                  }"
                >
                  {{ option.name }}
                </nb-option>
              </nb-autocomplete>
            </div>
            <textarea
              [formControl]="textPreview"
              type="text"
              nbInput
              fieldSize="small"
              id="text-preview-translate"
              style="
                height: 163px;
                max-height: 300px;
                min-height: 163px;
                margin-bottom: -4px;
                resize: none;
              "
              fullWidth
              (ngModelChange)="handleAutoHeight($event)"
              placeholder="Enter text"
            ></textarea>
          </div>
        </div>
        <div style="color: #8f9bb3">
          <button
            [disabled]="
              !selectedLanguage ||
              !selectedLanguageSource ||
              selectedLanguageSource == 'detect'
            "
            (click)="swapLanguage()"
            nbButton
            ghost
            status="basic"
            shape="round"
            size="small"
          >
            <nb-icon
              title="Swap language"
              icon="swap-outline"
              style="cursor: pointer"
            ></nb-icon>
          </button>
        </div>
        <div style="width: 34rem; margin-bottom: 0.5rem">
          <div class="translate-text-wrapper">
            <div style="margin-bottom: 0.5rem">
              <input
                id="languageText"
                [formControl]="languageText"
                type="text"
                nbInput
                fullWidth
                fieldSize="small"
                placeholder="Target Language"
                [nbAutocomplete]="autoNgModel"
              />
              <nb-autocomplete #autoNgModel>
                <nb-option
                  *ngFor="let option of filteredLanguageTest$ | async"
                  [value]="option.name"
                  [ngClass]="{
                    'bg-active': option.name == languageText?.value
                  }"
                >
                  {{ option.name }}
                </nb-option>
              </nb-autocomplete>
            </div>
            <textarea
              *ngIf="compareList.length == 0"
              style="
                height: 163px;
                max-height: 300px;
                min-height: 163px;
                margin-bottom: -4px;
                resize: none;
              "
              type="text"
              nbInput
              fieldSize="small"
              id="text-preview-translate-2"
              fullWidth
              readonly
              placeholder="Translation"
            ></textarea>
            <div
              style="width: 100%"
              *ngIf="compareList.length > 0"
              class="compare-wrapper"
            >
              <nb-list>
                <nb-list-item
                  *ngFor="let item of compareList; let i = index"
                  style="font-size: 13px; position: relative"
                >
                  <div class="nation-item">
                    <div style="width: 100%; word-break: break-word">
                      <span
                        style="color: #3367d6; font-weight: 600"
                        [ngClass]="{ gray: item.result.duration == -1 }"
                        >{{ item.service
                        }}{{ item.result.duration != -1 ? ": " : " " }}
                      </span>
                      <span *ngIf="item.result.duration != -1">{{
                        item.result.text
                      }}</span>
                      <span
                        *ngIf="item.result.duration == -1"
                        style="color: gray !important"
                        >(Not Support or Error)</span
                      >
                      <span
                        style="position: absolute; top: 0; right: 3px"
                        *ngIf="item.result.duration != -1"
                        class="duration-rs"
                        [ngClass]="{
                          'duration-red': item.result.duration > 1000
                        }"
                        >{{ item.result.duration }}ms</span
                      >
                    </div>

                    <!-- <div
                      *ngIf="item.result.duration != -1"
                      class="nation-item-name"
                      style="width: 6%"
                    >
                      <span
                        class="duration-rs"
                        [ngClass]="{
                          'duration-red': item.result.duration > 1000
                        }"
                        >{{ item.result.duration }}ms</span
                      >
                    </div>
                    <div
                      *ngIf="item.result.duration == -1"
                      class="nation-item-name"
                      style="pointer-events: none !important"
                    >
                      <span class="duration-rs" style="color: gray !important"
                        >(Not Support or Error)</span
                      >
                    </div> -->
                  </div>
                </nb-list-item>
              </nb-list>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
