<nb-card
  style="margin-bottom: 0"
  [nbSpinner]="isLoading"
  nbSpinnerStatus="primary"
>
  <nb-card-header>
    <div class="d-flex justify-content-between">
      <div class="title-page-wrapper">
        <span>Revision History</span
        ><nb-icon
          nbTooltip="Revision history of your flow."
          nbTooltipPlacement="right"
          nbTooltipTrigger="hover"
          nbTooltipStatus="basic"
          (click)="moreInfo()"
          icon="question-mark-circle-outline"
        ></nb-icon>
      </div>
      <button
        (click)="dismiss()"
        type="button"
        class="btn-close"
        aria-label="Close"
      ></button></div
  ></nb-card-header>
  <nb-card-body style="padding-top: 0; padding-bottom: 0">
    <div class="row revision-wrapper">
      <div *ngIf="currentRow?.id != null; then haveData; else noneData"></div>
      <ng-template #haveData class="child">
        <div class="layout-revision">
          <nb-layout-column class="layout-left pe-2" fixed>
            <ngx-datatable
              #table
              class="bootstrap material table-height"
              [footerHeight]="50"
              rowHeight="32"
              [selectionType]="'single'"
              [selected]="selected"
              (activate)="onActivate($event)"
              [rows]="dataDrafts"
              [limit]="dataParams.page_size"
            >
              <ngx-datatable-column
                name="Version"
                [width]="60"
                [draggable]="false"
                [resizeable]="false"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.version }}
                  <nb-icon
                    style="font-size: 14px; color: #3367d6; margin-left: 5px"
                    icon="star"
                    [ngClass]="row.isPublished ? '' : ' displayIcon'"
                  ></nb-icon>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                name="Last Modified By"
                prop="lastModifiedEmail"
                [width]="160"
                [draggable]="false"
                [resizeable]="false"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <nb-user
                    matTooltip="{{ row.lastModifiedEmail }}"
                    size="small"
                    [matTooltipPosition]="'right'"
                    name="{{ row.lastModifiedEmail }}"
                    picture="{{ row.lastModifiedAvatar }}"
                  >
                  </nb-user>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                name="Last Modified Date"
                prop="changedDate"
                [width]="160"
                [draggable]="false"
                [resizeable]="false"
              >
                <ng-template let-value="value" ngx-datatable-cell-template>
                  {{ value | date : "MM/dd/yyyy, HH:mm:ss" }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="Note"
                [width]="200"
                [draggable]="false"
                [resizeable]="false"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span>{{ row.note }}</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                name="Tag"
                prop="tag"
                [width]="80"
                [draggable]="false"
                [resizeable]="false"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <label class="tag" *ngIf="row.tag">{{ row.tag }}</label>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                [width]="50"
                [draggable]="false"
                [resizeable]="false"
                [sortable]="false"
              >
                <ng-template let-value="value" ngx-datatable-cell-template>
                  <div class="action-wrapper">
                    <button
                      ghost
                      class="tbl-action"
                      nbButton
                      status="primary"
                      size="tiny"
                      nbPopoverTrigger="noop"
                      (click)="showPopup()"
                      nbPopoverPlacement="bottom"
                      [nbPopover]="templateRef"
                    >
                      <nb-icon icon="edit-2-outline"></nb-icon>
                    </button>
                    <div></div>
                  </div>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </nb-layout-column>
          <div class="layout-mid"></div>
          <nb-layout-column class="layout-right border">
            <a
              data-fancybox="gallery"
              [attr.data-caption]="currentRow?.version"
              [href]="currentRow?.image"
              class="img-wrapper"
              style="cursor: zoom-in !important"
              (click)="setBackground()"
            >
              <img class="img-fluid img-responsive" [src]="currentRow?.image" />
            </a>
          </nb-layout-column>
        </div>
      </ng-template>
      <ng-template class="d-flex align-items-center" #noneData
        ><span style="margin-top: 1rem"
          >No Version. Please save for new version.</span
        ></ng-template
      >
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="footer w-100 d-flex justify-content-end">
      <button
        [disabled]="isReadOnly == true"
        nbButton
        status="primary"
        size="small"
        class="ms-3 text-white"
        (click)="revert()"
      >
        <nb-icon icon="flip-2-outline"></nb-icon>Revert
      </button>
    </div></nb-card-footer
  >
</nb-card>
<ng-template #templateRef>
  <nb-card style="margin-bottom: 0; width: 400px">
    <nb-card-header>
      <div class="d-flex justify-content-between">
        <div>
          Version: {{ currentRow.version }}
          <nb-icon
            style="font-size: 14px; color: #3367d6"
            icon="star"
            [ngClass]="currentRow.isPublished ? '' : ' displayIcon'"
          ></nb-icon>
        </div>
        <button
          (click)="hiddenPopup()"
          type="button"
          class="btn-close"
          aria-label="Close"
        ></button></div
    ></nb-card-header>
    <nb-card-body>
      <div class="mb-2">
        <label for="tag" class="form-label label">Tag</label>
        <input
          id="tag"
          type="text"
          nbInput
          [(ngModel)]="currentRow.tag"
          fullWidth
          placeholder="Tag"
          (keyup.enter)="updateReversion()"
        />
      </div>
      <div class="mb-2">
        <label for="note" class="form-label label">Note</label>
        <textarea
          id="note"
          style="height: 39.6px; max-height: 200px; min-height: 39.6px"
          type="text"
          [(ngModel)]="currentRow.note"
          nbInput
          fullWidth
          id="text-input"
          (ngModelChange)="handleHeight()"
          placeholder="Note"
        ></textarea>
      </div>
      <div class="float-end mt-2">
        <button
          [disabled]="isLoadingButton || isReadOnly == true"
          (click)="updateReversion()"
          type="submit"
          class="me-2"
          status="primary"
          size="small"
          nbButton
        >
          Update
        </button>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
