import { DataService } from "./../../@core/utils/data.service";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NbWindowRef } from "@nebular/theme";
import { TextToSpeech } from "../../@core/model/text-to-speech";
import {
  AudioClient,
  ConvertAudioCommand,
  CreateAudioLanguageCommand,
} from "../../System-api";

@Component({
  selector: "ngx-text-to-speech-modal",
  templateUrl: "./text-to-speech-modal.component.html",
  styleUrls: ["./text-to-speech-modal.component.scss"],
})
export class TextToSpeechModalComponent implements OnInit {
  @ViewChild("testAudio", { static: true })
  public testAudio: ElementRef;
  listNation;
  @Input() dataText = [];
  @Input() dataTextBot = [];
  data: TextToSpeech = new TextToSpeech();
  currentTTS = [];
  currentTTSBot = [];
  isNoInput: boolean = false;
  isNotMatch: boolean = false;
  previousLength = 0;
  isChatBot: boolean = false;
  checkNull: boolean = false;
  isPlaying: boolean;
  audioBefore: CreateAudioLanguageCommand;
  dataAudio;
  attributes: any = this.dataService.ivrAttribute;
  ivrFunction: any = this.dataService.ivrFunction;
  mentionConfig = {
    mentions: [
      {
        items: this.attributes,
        triggerChar: "{",
        labelKey: "value",
        disableSort: true,
        mentionSelect: (item) => {
          return "{" + item.value + "}";
        },
      },
      {
        items: this.ivrFunction,
        triggerChar: ">",
        labelKey: "value",
        disableSort: true,
        mentionSelect: (item) => {
          return ">" + item.value;
        },
      },
    ],
  };
  selectedTTS: EventEmitter<TextToSpeech> = new EventEmitter<TextToSpeech>();
  constructor(
    protected windowRef: NbWindowRef,
    private dataService: DataService,
    private audioClient: AudioClient
  ) {}
  ngOnInit() {
    this.audioBefore = new CreateAudioLanguageCommand();
    this.getNationSetting();
    if (this.checkNull) {
      if (this.isNoInput) {
        this.setDefaultNoInput();
      }
      if (this.isNotMatch) {
        this.setDefaultNotMatch();
      }
    }
  }
  trackByFn(index, item) {
    return index;
  }
  checkDefaultLanguageUser(data) {
    let check = false;
    if (data.defaultUser === true) {
      check = true;
    }
    return check;
  }
  getNationSetting() {
    this.listNation = JSON.parse(this.dataService.CallSetting.languageSetting);
    if (this.currentTTS.length > 0 || this.currentTTSBot.length > 0) {
      this.data.text = JSON.stringify(this.currentTTS);
      this.dataText = this.currentTTS;
      if (this.isChatBot) {
        this.data.textChatBot = JSON.stringify(this.currentTTSBot);
        this.dataTextBot = this.currentTTSBot;
      }
    }
    this.handleBindingData(this.dataText);
    if (this.isChatBot) {
      this.handleBindingData(this.dataTextBot);
    }
  }
  handleBindingData(data: any) {
    this.listNation.forEach((obj) => {
      let check = 0;
      for (let i = 0; i < data.length; i++) {
        const languageSetting = obj.language.split("-");
        const languageTTS = data[i].language.split("-");
        if (languageSetting[0] == languageTTS[0]) {
          data[i].name = obj.name;
          data[i].language = obj.language;
          data[i].defaultUser = obj.defaultUser;
          check = 1;
        }
      }
      if (check == 0) {
        var objText = { text: "" };
        obj = { ...obj, ...objText };
        delete obj["service"];
        delete obj["voiceId"];
        delete obj["gender"];
        data.push(obj);
      }
    });
    for (let j = 0; j < data.length; j++) {
      let check = 0;
      this.listNation.forEach((item) => {
        const languageSetting = data[j].language.split("-");
        const languageTTS = item.language.split("-");
        if (languageSetting[0] == languageTTS[0]) {
          check = 1;
        }
      });
      if (check == 0) {
        if (j != 0) {
          data.splice(j, 1);
          j--;
        } else {
          data.shift();
          j--;
        }
      }
    }
  }
  shuffle(index) {
    let newData = "";
    let data = this.dataText[index].text;
    let dataArray = data.split("\u2022");
    dataArray = this.handleRemoveDataEmpty(dataArray);
    dataArray.forEach((_, i) => {
      const j = Math.floor(Math.random() * (i + 1));
      [dataArray[i], dataArray[j]] = [dataArray[j], dataArray[i]];
    });
    newData = "\u2022 ";
    dataArray.forEach((_, i) => {
      newData = newData + dataArray[i].trim() + " \n\u2022 ";
    });
    this.dataText[index].text = newData.slice(0, -3);
    this.onChangeText();
  }
  handleRemoveDataEmpty(data) {
    for (let j = 0; j < data.length; j++) {
      if (data[j] == "") {
        if (j != 0) {
          data.splice(j, 1);
        } else {
          data.shift();
        }
        j--;
      }
    }
    return data;
  }
  onChangeText(event: any = null) {
    if (event && (this.isNoInput == true || this.isNotMatch == true)) {
      this.data.text = this.handleChangeContent(event);
    }
    this.data.text = JSON.stringify(this.dataText);
    if (this.isChatBot) {
      this.data.textChatBot = JSON.stringify(this.dataTextBot);
    }
    this.selectedTTS.emit(this.data);
  }
  onChangeTextBot() {
    this.data.text = JSON.stringify(this.dataText);
    this.data.textChatBot = JSON.stringify(this.dataTextBot);
    this.selectedTTS.emit(this.data);
  }
  handleChangeContent(event) {
    const text = event.target.value;

    // Save the selection range
    const selectionStart = event.srcElement.selectionStart;
    const selectionEnd = event.srcElement.selectionEnd;

    // Replace the text
    let newText = text.replace(/(^|\n)([^\u2022])/g, "$1\u2022 $2");
    newText = newText.replace(/\u2022\s*\u2022/g, "\u2022");
    event.srcElement.value = newText;

    // Restore the selection range
    const newSelectionStart = selectionStart + (newText.length - text.length);
    const newSelectionEnd = selectionEnd + (newText.length - text.length);
    event.srcElement.setSelectionRange(newSelectionStart, newSelectionEnd);
    return newText;
  }
  setDefaultNoInput() {
    for (let i = 0; i < this.dataText.length; i++) {
      const language = this.dataText[i].language.split("-");
      if (this.dataText[i].text == "" && language[0] == "en") {
        this.dataText[i].text =
          "\u2022 Sorry, I didn't hear you \n\u2022 I'm sorry, I'm having a hard time hearing you.";
      }
    }
    this.onChangeText();
  }
  setDefaultNotMatch() {
    for (let i = 0; i < this.dataText.length; i++) {
      const language = this.dataText[i].language.split("-");
      if (this.dataText[i].text == "" && language[0] == "en") {
        this.dataText[i].text =
          "\u2022 Sorry, I didn't understand that \n\u2022 I'm sorry, I didn't understand what you said.";
      }
    }
    this.onChangeText();
  }
  selectCurrentTTS(text) {
    try {
      this.currentTTS = JSON.parse(text);
    } catch (e) {
      this.currentTTS = [];
    }
  }
  selectCurrentTTSBot(text) {
    try {
      this.currentTTSBot = JSON.parse(text);
    } catch (e) {
      this.currentTTSBot = [];
    }
  }
  drop($event, nation) {
    nation.text += "{" + $event.dataTransfer.getData("text").trim() + "}";
    this.data.text = JSON.stringify(this.dataText);
    this.selectedTTS.emit(this.data);
    $event.preventDefault();
  }
  dropBot($event, nation) {
    nation.textChatBot +=
      "{" + $event.dataTransfer.getData("text").trim() + "}";
    this.data.textChatBot = JSON.stringify(this.dataTextBot);
    this.selectedTTS.emit(this.data);
    $event.preventDefault();
  }

  convertTTS(data) {
    const audio = this.listNation.find((obj) => obj.name == data.name);

    if (this.isPlaying) {
      this.isPlaying = false;
      this.testAudio.nativeElement.pause();
      this.testAudio.nativeElement.currentTime = 0;
      return;
    }

    if (
      this.audioBefore.content == data.text &&
      this.audioBefore.service == audio.service &&
      this.audioBefore.language == audio.language &&
      this.audioBefore.voiceId == audio.voiceId
    )
      this.testAudio.nativeElement.play();
    else {
      this.audioBefore.content = data.text;
      this.audioBefore.service = audio.service;
      this.audioBefore.language = audio.language;
      this.audioBefore.voiceId = audio.voiceId;

      this.audioClient
        .convert(
          new ConvertAudioCommand({
            content: data.text,
            service: audio.service,
            language: audio.language,
            voiceId: audio.voiceId,
          })
        )
        .subscribe((result) => {
          this.dataAudio = result;
          this.isPlaying = true;
        });
    }
  }

  audioPlaying() {
    this.isPlaying = true;
  }
  audioEnded() {
    this.isPlaying = false;
  }
}
