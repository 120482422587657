import { Component, OnInit } from "@angular/core";

@Component({
  selector: "autocomplete-select",
  templateUrl: "autocomplete-select.component.html",
  styleUrls: ["./autocomplete-select.component.scss"],
})
export class AutocompleteSelectComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
