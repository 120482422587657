import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import {
  CallFlowClient,
  RequestModificationFlowCommand,
} from "../../../../System-api";
import { Subscription } from "rxjs";
import { SignalRService } from "../../../../@core/utils/signalR.service";

@Component({
  selector: "request-modification",
  templateUrl: "request-modification.component.html",
})

// request, response , wait, cancel ,deny,grant, readonly, savecopy
export class RequestModificationDialogComponent
  implements OnDestroy, AfterViewInit
{
  fromUser: string;
  toUser: string;
  processRequest: string = "request";
  question: any;
  questionWait: any;
  grantPermissionStatusSubscription: Subscription;
  waitTime: number = 30; //seconds
  countdownIntervalResponse: any;
  countdownIntervalRequest: any;

  constructor(
    protected ref: NbDialogRef<RequestModificationDialogComponent>,
    private callFlowClient: CallFlowClient,
    private signalRService: SignalRService
  ) {
    this.grantPermissionStatusSubscription =
      this.signalRService.grantPermissionStatus.subscribe((rs) => {
        if (rs === "deny") {
          this.processRequest = "deny";
          this.question = `Your request has been denied by the ${this.toUser}`;
          setTimeout(() => {
            this.processRequest = "request";
            clearInterval(this.countdownIntervalRequest);
            this.waitTime = 30;
          }, 2000);
        } else if (rs === "grant") {
          this.processRequest = "grant";
          this.question = `Your request has been approved by the ${this.toUser}. Now you can modify this flow.`;
          setTimeout(() => {
            this.ref.close("grant");
          }, 2000);
        } else {
          this.dismiss("cancel");
        }
      });
  }
  ngAfterViewInit(): void {
    if (this.processRequest == "response") {
      this.question = `Do you want to grant ${this.fromUser} permission to modify this flow?<br><br>
        Click Yes to exit the flow design or click No to stay <br><br>
        No action will exit this callflow design in ${this.waitTime} seconds.`;
      this.startCountdownResponse();
    }
  }

  startCountdownResponse() {
    this.countdownIntervalResponse = setInterval(() => {
      this.waitTime--;

      this.question = `Do you want to grant ${this.fromUser} permission to modify this flow?<br><br>
      Click Yes to exit the flow design or click No to stay <br><br>
      No action will exit this callflow design in ${this.waitTime} seconds.`;

      if (this.waitTime <= 0) {
        clearInterval(this.countdownIntervalResponse);
        this.dismiss("grant");
      }
    }, 1000);
  }

  startCountdownRequest() {
    this.countdownIntervalRequest = setInterval(() => {
      this.waitTime--;

      this.questionWait = `Please wait for ${this.toUser} to confirm your request.<br><br>
      If ${this.toUser} no action, you will have permission to modify this callflow in ${this.waitTime} second.`;

      if (this.waitTime <= 0) {
        clearInterval(this.countdownIntervalRequest);

        setTimeout(() => {
          if (this.processRequest != "grant") {
            this.processRequest = "grant";
            this.question = `Your request has been approved by the ${this.toUser}. Now you can modify this flow.`;
            setTimeout(() => {
              this.ref.close("grant");
            }, 2000);
          }
        }, 2000);
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    this.grantPermissionStatusSubscription.unsubscribe();
    if (this.countdownIntervalRequest) {
      clearInterval(this.countdownIntervalRequest);
    }

    if (this.countdownIntervalResponse) {
      clearInterval(this.countdownIntervalResponse);
    }
  }
  requestPermission() {
    this.callFlowClient
      .requestModificationFlow(
        new RequestModificationFlowCommand({
          requestFromUser: this.fromUser,
          requestToUser: this.toUser,
          type: "request",
        })
      )
      .subscribe((result) => {
        this.processRequest = "wait";
        this.questionWait = `Please wait for ${this.toUser} to confirm your request.<br><br>
        If ${this.toUser} no action, you will have permission to modify this callflow in ${this.waitTime} second.`;
        this.startCountdownRequest();
      });
  }

  cancelRequest() {
    this.processRequest = "request";
    clearInterval(this.countdownIntervalRequest);
    this.waitTime = 30;

    this.callFlowClient
      .requestModificationFlow(
        new RequestModificationFlowCommand({
          requestFromUser: this.fromUser,
          requestToUser: this.toUser,
          type: "cancel",
        })
      )
      .subscribe((result) => {});
  }

  dismiss(result) {
    this.ref.close(result);
  }

  readOnly() {
    this.ref.close("readonly");
  }

  onSubmit() {
    this.ref.close("savecopy");
  }
}
