<audio
  (playing)="audioPlaying()"
  (ended)="audioEnded()"
  #testAudio
  [src]="dataAudio ? 'data:audio/mp3;base64,' + dataAudio : ''"
  autoplay
>
  <source />
</audio>
<div style="margin-bottom: 1rem">
  <nb-accordion>
    <nb-accordion-item
      [expanded]="checkDefaultLanguageUser(data)"
      *ngFor="let data of dataText; trackBy: trackByFn; let i = index"
    >
      <nb-accordion-item-header class="is-call-flow-name">{{
        data.name
      }}</nb-accordion-item-header>
      <nb-accordion-item-body>
        <div>
          <div class="text-position">
            <div *ngIf="isChatBot" class="main">voice</div>
          </div>
          <textarea
            (drop)="drop($event, data)"
            style="max-height: 10em; min-height: 5em"
            [(ngModel)]="data.text"
            nbInput
            fullWidth
            [ngClass]="
              selectedNation == data.code
                ? 'text-area-audio-tab show'
                : 'text-area-audio-tab'
            "
            class="text-area-audio-tab"
            id="text-area-{{ data.code }}"
            placeholder="Text For {{ data.name }}"
            (input)="onChangeText($event)"
            [mentionConfig]="mentionConfig"
          >
          </textarea>
          <div style="text-align: right">
            <button
              class="icon-action"
              ghost
              [disabled]="data.text == ''"
              (click)="convertTTS(data)"
              type="button"
              size="small"
              status="primary"
              nbButton
            >
              <nb-icon
                [icon]="
                  isPlaying ? 'stop-circle-outline' : 'play-circle-outline'
                "
              ></nb-icon>
            </button>

            <button
              *ngIf="isNotMatch == true || isNoInput == true"
              ghost
              nbButton
              status="primary"
              class="icon-action"
              size="small"
              (click)="shuffle(i)"
            >
              <nb-icon icon="shuffle-outline"></nb-icon>
            </button>
          </div>
        </div>
        <div *ngIf="isChatBot">
          <div class="text-position">
            <div class="main">chat</div>
          </div>
          <textarea
            (drop)="dropBot($event, dataTextBot[i])"
            style="max-height: 10em; min-height: 5em"
            [(ngModel)]="dataTextBot[i].textChatBot"
            nbInput
            fullWidth
            [ngClass]="
              selectedNation == dataTextBot[i].code
                ? 'text-area-audio-tab show'
                : 'text-area-audio-tab'
            "
            class="text-area-audio-tab"
            id="text-area-{{ dataTextBot[i].code }}"
            placeholder="Text For {{ dataTextBot[i].name }}"
            (ngModelChange)="onChangeTextBot()"
            [mentionConfig]="mentionConfig"
          >
          </textarea>
        </div>
      </nb-accordion-item-body>
    </nb-accordion-item>
  </nb-accordion>
</div>
