import { Component, OnInit, Directive } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { NbDialogRef } from "@nebular/theme";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { ChangePasswordCommand, UserClient } from "../../System-api";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: UntypedFormGroup;
  showOldPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;
  isLoading: boolean = false;
  constructor(
    protected ref: NbDialogRef<ChangePasswordComponent>,
    private formBuilder: RxFormBuilder,
    private userClient: UserClient
  ) {
    this.createForm();
  }

  ngOnInit(): void {}

  createForm() {
    this.changePasswordForm = this.formBuilder.group({
      oldpassword: ["", RxwebValidators.required()],
      newpassword: ["", RxwebValidators.required()],
      confirmpassword: ["", RxwebValidators.required()],
    });
  }

  dismiss() {
    this.ref.close();
  }

  toggleShowPassword(controlName) {
    if (controlName == "oldpassword") {
      this.showOldPassword = !this.showOldPassword;
    } else if (controlName == "newpassword") {
      this.showNewPassword = !this.showNewPassword;
    } else if (controlName == "confirmpassword") {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }

  getInputType(controlName) {
    if (controlName == "oldpassword") {
      if (this.showOldPassword) {
        return "text";
      }
      return "password";
    } else if (controlName == "newpassword") {
      if (this.showNewPassword) {
        return "text";
      }
      return "password";
    } else if (controlName == "confirmpassword") {
      if (this.showConfirmPassword) {
        return "text";
      }
      return "password";
    }
  }

  checkPasswordSyntax(value, controlName) {
    let password = value;
    if (password.length == 0) return;
    if (controlName != "confirmpassword") {
      if (password && /\s/.test(password)) {
        this.changePasswordForm.get(controlName).setErrors({ notvalid: true });
      } else if (password?.length > 15) {
        this.changePasswordForm.get(controlName).setErrors({ maxLength: true });
      } else if (password?.length < 6) {
        this.changePasswordForm.get(controlName).setErrors({ minLength: true });
      } else if (!/[A-Z]/.test(password)) {
        this.changePasswordForm.get(controlName).setErrors({ uppercase: true });
      } else if (!/[a-z]/.test(password)) {
        this.changePasswordForm.get(controlName).setErrors({ lowercase: true });
      } else if (!/[0-9]/.test(password)) {
        this.changePasswordForm.get(controlName).setErrors({ digit: true });
      } else {
        if (
          this.changePasswordForm.get("oldpassword")?.value ==
          this.changePasswordForm.get("newpassword")?.value
        ) {
          this.changePasswordForm
            .get("newpassword")
            .setErrors({ sameOldPassword: true });
        } else if (
          this.changePasswordForm.get("oldpassword")?.value !=
          this.changePasswordForm.get("newpassword")?.value
        ) {
          if (
            this.changePasswordForm
              .get("newpassword")
              .hasError("sameOldPassword")
          ) {
            delete this.changePasswordForm.get("newpassword").errors[
              "sameOldPassword"
            ];
            this.changePasswordForm.get("newpassword").updateValueAndValidity();
          }
        }
      }
    }
    if (
      controlName == "confirmpassword" &&
      this.changePasswordForm.get("newpassword")?.value != value
    ) {
      this.changePasswordForm
        .get("confirmpassword")
        .setErrors({ notmatch: true });
    } else if (
      controlName == "newpassword" &&
      this.changePasswordForm.get("confirmpassword")?.value != "" &&
      this.changePasswordForm.get("confirmpassword")?.value != value
    ) {
      this.changePasswordForm
        .get("confirmpassword")
        .setErrors({ notmatch: true });
    } else if (
      this.changePasswordForm.get("confirmpassword")?.value ==
      this.changePasswordForm.get("newpassword")?.value
    ) {
      if (this.changePasswordForm.get("confirmpassword").hasError("notmatch")) {
        delete this.changePasswordForm.get("confirmpassword").errors[
          "notmatch"
        ];
        this.changePasswordForm.get("confirmpassword").updateValueAndValidity();
      }
    }
  }

  setValidationErrors(errorData) {
    var errorData = JSON.parse(errorData);
    if (errorData) {
      for (const [key, value] of Object.entries(errorData)) {
        const fieldName = key.toLowerCase(); // Ensure case matches form control names
        const errorMessage = value[0];
        if (this.changePasswordForm.get(fieldName)) {
          const control = this.changePasswordForm.get(fieldName);
          control.setErrors({ serverError: errorMessage });
          control.markAsDirty();
        }
      }
    }
  }

  onSubmit() {
    var changePasswordModel = new ChangePasswordCommand({
      oldPassword: this.changePasswordForm.controls["oldpassword"]?.value,
      newPassword: this.changePasswordForm.controls["newpassword"]?.value,
      confirmPassword:
        this.changePasswordForm.controls["confirmpassword"]?.value,
    });
    this.isLoading = true;
    this.userClient.changePassword(changePasswordModel).subscribe({
      next: (result) => {
        this.isLoading = false;
        if (result) {
          this.ref.close(true);
        } else {
          this.ref.close(false);
        }
      },
      error: (error) => {
        this.isLoading = false;
        if (error.status == 422) {
          this.setValidationErrors(error.response);
        }
      },
    });
  }
}
