import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NbAuthJWTInterceptor, NbAuthJWTToken } from "@nebular/auth";
import { Observable, switchMap } from "rxjs";

@Injectable()
export class CustomInterceptor extends NbAuthJWTInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // if (!this.filter(req)) {
        //     return this.authService.isAuthenticatedOrRefresh()
        //         .pipe(switchMap(authenticated => {
        //             if (authenticated) {
        //                 return this.authService.getToken().pipe(switchMap((token) => {
        //                     const JWT = `Bearer ${token.getValue()}`;
        //                     req = req.clone({
        //                         setHeaders: {
        //                             Authorization: JWT,
        //                         },
        //                     });
        //                     return next.handle(req);
        //                 }));
        //             }
        //             else {
        //                 // Request is sent to server without authentication so that the client code
        //                 // receives the 401/403 error and can act as desired ('session expired', redirect to login, aso)
        //                 return next.handle(req);
        //             }
        //         }));
        // }
        // else {
        //     return next.handle(req);
        // }
        // return this.authService.getToken().pipe(switchMap((token: NbAuthJWTToken) => {
        //     if (!token.isValid() && req.url.includes("/auth/refresh-token")) {
        //         const token = JSON.parse(localStorage.getItem('auth_app_token') || '{}');
        //         const refreshToken = token?.value;
        //         const cloned = req.clone({
        //             headers: req.headers.set('Authorization', 'Bearer ' + refreshToken),
        //         });
        //         this.authService.refreshToken('email').subscribe((newToken: any) => {
        //             //console.log((newToken));
        //             // return next.handle(cloned);
        //             // this.router.navigate(['auth']);
        //         });
        //     }
        //     if (token) {
        //         const JWT = `${token.getValue()}`;
        //         req = req.clone({
        //             setHeaders: {
        //                 Authorization: JWT,
        //             },
        //         });
        //     }
        //     return next.handle(req);
        // }));
        if (req.url.includes("/auth/refresh-token")) {
            const token = JSON.parse(localStorage.getItem('auth_app_token') || '{}');
            const refreshToken = token?.value;
            if (refreshToken) {
                const cloned = req.clone({
                    headers: req.headers.set('Authorization', 'Bearer ' + refreshToken),
                });
                return next.handle(cloned);
            } else {
                return next.handle(req);
            }
        } else {
            return next.handle(req);
        }
    }
} 