export const host = "http://localhost:5001";
export const apiUrl = `${host}/api`;
export const tenantId = "0f718f1c-9d45-48d5-ad1b-9d5fe20e6439";
export const documentation =
  "https://docs.primas.net/display/CAL/Project+documents";
export const environment = {
  production: true,
  clientIdMS: "33ba8d66-622f-4c1e-b53d-50f7d2ae77bd",
  clientIdGoogle:
    "851197392253-7hh4imf64ai7jgldrfftaoc8cvsvqjue.apps.googleusercontent.com",
  authority: `https://login.microsoftonline.com/${tenantId}`,
  redirectUri: "http://localhost:5001",
};
