<div id="chat-popup">
  <div class="chat-container">
    <nb-card style="margin-bottom: 0; border: none">
      <nb-card-body style="padding: 0">
        <iframe
          id="primas-chat-frame"
          class="chat-content"
          [src]="link | safe"
          frameborder="0"
        ></iframe>
      </nb-card-body>
    </nb-card>
  </div>
</div>
