<div class="is-modal">
  <div class="flex-start set-media-title">Set Sentiment</div>
  <div class="flex-header" style="padding-bottom: 1rem">
    <button
      nbButton
      status="primary"
      size="tiny"
      class="btn-create"
      (click)="addSentiment()"
    >
      <nb-icon icon="plus-outline"> </nb-icon>Sentiment
    </button>
  </div>
</div>
