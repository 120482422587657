import { IApplicationData } from "./common";
export class TranslateAction implements IApplicationData {
callerNumber: any;
  
  constructor() {
    this.name = "Translate";
    this.sourceLanguage = "";
    this.targetLanguage = "";
    this.sourceLanguageType = false;
    this.targetLanguageType = false;
    this.codeSourceLanguage = "";
    this.codeTargetLanguage = "";
    this.text = "";
    this.attribute = "";
    this.transition = true;
    this.isRequired = true;
    this.checkFields = false;
  }
  name: string;
  sourceLanguage: string;
  targetLanguage: string;
  sourceLanguageType: boolean;
  targetLanguageType: boolean;
  codeSourceLanguage: string;
  codeTargetLanguage: string;
  text: string;
  attribute: string;
  transition: boolean;
  isRequired: boolean;
  checkFields: boolean;
}
