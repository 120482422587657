import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NbPopoverDirective } from "@nebular/theme";
import { Observable, of } from "rxjs";

@Component({
  selector: "app-textarea-highlight",
  templateUrl: "./textarea-highlight.component.html",
  styleUrls: ["./textarea-highlight.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaHighlightComponent),
      multi: true,
    },
  ],
})
export class TextareaHighlightComponent
  implements ControlValueAccessor, OnInit
{
  constructor(private renderer: Renderer2) {}

  @Input() textValue: string = "";
  @Input() entity: any[] = [];
  @Input() placeHolder: string;
  @Output() textChange = new EventEmitter<any>();
  @Output() handleAddEntity = new EventEmitter<any>();
  @ViewChild("backdrop") $backdrop: ElementRef<HTMLDivElement>;
  @ViewChild("container") $container: ElementRef<HTMLDivElement>;
  @ViewChild("textarea") $textarea: ElementRef<HTMLTextAreaElement>;
  @ViewChild("autoInput") input;
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  @ViewChild("highlight") highlight: ElementRef<HTMLDivElement>;

  selectedText: string;
  valueData: string;
  options: string[] = [];
  filteredOptions$: Observable<string[]>;
  previousLength = 0;

  get highlightedText() {
    return this.applyHighlights(this.textValue);
  }
  ngOnInit() {}
  updateEntity() {
    this.options = [];
    this.entity?.forEach((item) => {
      this.options.push(item.label);
    });
    this.filteredOptions$ = of(this.options);
  }
  applyHighlights(text) {
    text = text ? text.replace(/\n$/g, "\n\n") : "";
    this.entity?.forEach(function (item, index) {
      item?.value.forEach((element) => {
        const regexPattern = `\\b${element}\\b`;
        var regex = new RegExp(regexPattern, "gi");
        text = text.replace(
          regex,
          "<mark class='color-highlight-" + index + "'>$&</mark>"
        );
      });
    });
    return text;
  }
  handleScroll() {
    var scrollTop = this.$textarea.nativeElement.scrollTop;
    this.$backdrop.nativeElement.scrollTop = scrollTop;
    var scrollLeft = this.$textarea.nativeElement.scrollLeft;
    this.$backdrop.nativeElement.scrollLeft = scrollLeft;
  }
  onChangeContent(event) {
    const text = event.target.value;

    // Save the selection range
    const selectionStart = event.srcElement.selectionStart;
    const selectionEnd = event.srcElement.selectionEnd;

    // Replace the text
    let newText = text;
    if (event.inputType != "deleteContentBackward") {
      newText = text.replace(/(^|\n)([^\u2022])/g, "$1\u2022 $2");
      event.srcElement.value = newText;
    }

    // Restore the selection range
    const newSelectionStart = selectionStart + (newText.length - text.length);
    const newSelectionEnd = selectionEnd + (newText.length - text.length);
    event.srcElement.setSelectionRange(newSelectionStart, newSelectionEnd);
    this.textChange.emit(event.target.value);
  }
  handleEntity(event) {
    const check = this.checkValueExist(this.selectedText);
    if (event.target.value || event.target.value.trim() != "") {
      //update
      this.handleAddEntity.emit({
        label: event.target.value.trim(),
        value: this.selectedText,
        isUpdate: check,
        isRemove: false,
      });
    } else {
      if (check) {
        //remove
        this.handleAddEntity.emit({
          label: event.target.value.trim(),
          value: this.selectedText,
          isUpdate: false,
          isRemove: true,
        });
      }
    }
    this.popover.hide();
  }
  onSelect(event: Event) {
    const textarea = event.target as HTMLTextAreaElement;
    const selectionStart = textarea.selectionStart;
    const selectionEnd = textarea.selectionEnd;
    this.selectedText = textarea.value
      .substring(selectionStart, selectionEnd)
      .trim();
    this.updateEntity();
    this.getEntityByValue(this.selectedText);
    this.popover.show();
  }
  getEntityByValue(selectedText) {
    let entity = "";
    this.entity?.forEach((item) => {
      item?.value.forEach((value) => {
        if (value.toLowerCase() == selectedText.toLowerCase()) {
          entity = item.label;
        }
      });
    });
    this.valueData = entity;
  }
  checkValueExist(selectedText) {
    let check = false;
    this.entity?.forEach((item) => {
      item?.value.forEach((value) => {
        if (value == selectedText) {
          check = true;
        }
      });
    });
    return check;
  }
  onChanges: ($value: any) => void;
  onTouched: () => void;
  writeValue(value: any): void {
    if (value !== undefined) {
      this.textValue = value;
    }
  }
  registerOnChange(fn: any): void {
    this.onChanges = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  @HostListener("document:click", ["$event"])
  clickedOutside($event) {
    if (
      $event?.target?.className &&
      typeof $event?.target?.className === "string"
    ) {
      if (!$event?.target?.className?.includes("pop-up")) {
        this.popover.hide();
      }
    }
  }
  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((optionValue) =>
      optionValue.toLowerCase().includes(filterValue)
    );
  }

  onModelChange(value: string) {
    this.filteredOptions$ = of(this.filter(value));
  }
}
