import { IApplicationData } from "./common";
export class IntentRecognition implements IApplicationData {
  constructor() {
    this.name = "Intent Recognition";
    this.text = "";
    this.attribute = "";
    this.entityAttribute = "";
    this.grammarArray = "[]";
    this.multipleCase = true;
    this.transition = true;
    this.isRequired = true;
    this.checkFields = false;
  }
  name: string;
  text: string;
  attribute: string;
  entityAttribute: string;
  grammarArray: string;
  multipleCase: boolean;
  transition: boolean;
  isRequired: boolean;
  checkFields: boolean;
}
