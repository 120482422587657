<nb-layout>
  <nb-layout-column>
    <div id="notfound">
      <div class="notfound">
        <div class="notfound-404">
          <h1>
            4
            <span></span>
            4
          </h1>
        </div>
        <h2>Oops! Page Not Be Found</h2>
        <p>
          Sorry but the page you are looking for does not exist, have been removed. name changed or is temporarily
          unavailable
        </p>
        <button type="button" (click)="goToHome()" class="home-button" nbButton status="primary">
          <nb-icon icon="home-outline"></nb-icon>Back to homepage
        </button>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>
