<nb-card style="max-width: 95vw; max-height: 70vh" status="basic">
  <nb-card-header class="infor-header">
    <div class="d-flex justify-content-between">
      Keyboard Shortcuts
      <button
        (click)="dismiss()"
        type="button"
        class="btn-close"
        aria-label="Close"
      ></button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="shortcuts-wrapper">
      <div class="shortcuts-line">
        <div class="shortcuts-item">
          <div class="shortcuts-name">Undo</div>
          <div class="shortcuts-button">
            <button nbButton size="tiny" status="basic" outline>Ctrl</button>
            <span>+</span>
            <button nbButton size="tiny" status="basic" outline>Z</button>
          </div>
        </div>
        <div class="shortcuts-item">
          <div class="shortcuts-name">Redo</div>
          <div class="shortcuts-button">
            <button nbButton size="tiny" status="basic" outline>Ctrl</button>
            <span>+</span>
            <button nbButton size="tiny" status="basic" outline>Y</button>
          </div>
        </div>
        <div class="shortcuts-item">
          <div class="shortcuts-name">Copy</div>
          <div class="shortcuts-button">
            <button nbButton size="tiny" status="basic" outline>Ctrl</button>
            <span>+</span>
            <button nbButton size="tiny" status="basic" outline>C</button>
          </div>
        </div>
        <div class="shortcuts-item">
          <div class="shortcuts-name">Paste</div>
          <div class="shortcuts-button">
            <button nbButton size="tiny" status="basic" outline>Ctrl</button>
            <span>+</span>
            <button nbButton size="tiny" status="basic" outline>V</button>
          </div>
        </div>
        <div class="shortcuts-item">
          <div class="shortcuts-name">Delete</div>
          <div class="shortcuts-button">
            <button nbButton size="tiny" status="basic" outline>Delete</button>
          </div>
        </div>
        <div class="shortcuts-item">
          <div class="shortcuts-name">Zoom In</div>
          <div class="shortcuts-button">
            <button nbButton size="tiny" status="basic" outline>+</button>
          </div>
        </div>
        <div class="shortcuts-item">
          <div class="shortcuts-name">Zoom Out</div>
          <div class="shortcuts-button">
            <button nbButton size="tiny" status="basic" outline>-</button>
          </div>
        </div>
      </div>
      <div class="shortcuts-line">
        <div class="shortcuts-item">
          <div class="shortcuts-name">Select All</div>
          <div class="shortcuts-button">
            <button nbButton size="tiny" status="basic" outline>Ctrl</button>
            <span>+</span>
            <button nbButton size="tiny" status="basic" outline>A</button>
          </div>
        </div>
        <div class="shortcuts-item">
          <div class="shortcuts-name">Open Mini Map</div>
          <div class="shortcuts-button">
            <button nbButton size="tiny" status="basic" outline>M</button>
          </div>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
