import { DataService } from "./data.service";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  NavigationStart,
} from "@angular/router";
import { NbAuthService, NbTokenService } from "@nebular/auth";
import { tap } from "rxjs/operators";
import { NbRoleProvider, NbAccessChecker } from "@nebular/security";
import { Location } from "@angular/common";

@Injectable()
export class AuthGuard implements CanActivate {
  private navigationHistory: any[] = [];
  constructor(
    private tokenService: NbTokenService,
    private roleProvider: NbRoleProvider,
    private access: NbAccessChecker,
    private authService: NbAuthService,
    private router: Router,
    private location: Location,
    private dataService: DataService
  ) {}

  checkURLLogin() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.navigationHistory.push(this.location.path());
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let permission = "";
    let resource = "";
    let role;
    this.roleProvider.getRole().subscribe((rs) => {
      role = rs;
    });

    if (route.firstChild == undefined) {
      permission = route.data.permission;
      resource = route.data.resource;
    } else {
      permission = route.firstChild.data.permission;
      resource = route.firstChild.data.resource;
    }

    return this.access.isGranted(permission, resource).pipe(
      tap((result) => {
        if (!result) {
          this.checkURLLogin();
          if (role == "Agent") {
            this.router.navigate(["error"]);
          } else {
            if (role == "SuperAdmin") {
              this.router.navigate(["portal/manage-dashboard"]);
            } else if (role == "Admin" || role == "guest") {
              this.router.navigate(["portal/dashboard"]);
            } else {
              this.router.navigate(["auth/login"]);
            }
          }
        } else {
          if (route.routeConfig.path == "") {
            if (role == "Agent") {
              this.router.navigate(["portal/agent-workspace"]);
            } else if (role == "SuperAdmin") {
              this.router.navigate(["portal/manage-dashboard"]);
            } else {
              if (
                this.location.getState() &&
                this.location.getState()["navigationId"] == 2 &&
                this.navigationHistory[0] != ""
              ) {
                this.location.back();
              } else {
                this.router.navigate(["portal/studio/callflows"]);
              }
            }
          }
        }
      })
    );
  }
}
