<nb-card style="margin-bottom: 0; overflow: auto">
  <nb-card-header>
    <div class="d-flex justify-content-between">
      <span>Set Value For Params</span>
      <button
        (click)="ref.close(false)"
        type="button"
        class="btn-close"
        aria-label="Close"
      ></button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="row m-2">
      <div class="col-12">
        <div *ngFor="let item of paramsArray" class="row">
          <div class="col-md-6">
            <div class="mb-2">
              <label for="name" class="form-label label">Name</label>
              <input
                name="name"
                [value]="item.name"
                readonly
                type="text"
                nbInput
                fullWidth
                placeholder="Name"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-2">
              <label for="value" class="form-label label">Value</label>
              <input
                name="value"
                [(ngModel)]="item.value"
                type="text"
                nbInput
                fullWidth
                placeholder="Value"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button
      style="float: right"
      class="float-end"
      (click)="confirm()"
      nbButton
      size="small"
      status="primary"
    >
      Run
    </button></nb-card-footer
  >
</nb-card>
