import { Component, OnInit } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { SignalRService } from "../../../@core/utils/signalR.service";
import { Subscription } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { SettingsClient } from "../../../System-api";
import { version } from "../../../../assets/version";

@Component({
  selector: "upgrade-modal",
  templateUrl: "upgrade.component.html",
  styleUrls: ["./upgrade.component.scss"],
})
export class UpgradeComponent implements OnInit {
  currentUpgrade = "";
  upgradeLog = "";
  percentUpgrade = 0;
  serverFinished = false;
  lastLog;
  clientFinished = false;
  result = "";

  countDown = -1;
  listFeature;
  isStart = false;

  uuid = uuidv4();
  version = version || "2.9";

  constructor(
    protected ref: NbDialogRef<UpgradeComponent>,
    private signalRService: SignalRService,
    private settingClient: SettingsClient
  ) {}

  upgradeStatusSubscription: Subscription;

  ngOnInit() {
    this.settingClient.getNewsVersion(this.version).subscribe((rs) => {
      this.listFeature = rs;
    });
  }

  sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async simulateProcess(title: string, percent: number) {
    let timeout = 1000;
    if (this.serverFinished) {
      timeout = 100;
    }
    await this.sleep(timeout);
    this.percentUpgrade = percent;
    this.upgradeLog = title;
  }

  completeUpgrade() {
    this.countDown = 10;
    this.settingClient.reset().subscribe((rs) => {
      const intervalId = setInterval(() => {
        this.countDown--;
        if (this.countDown <= 0) {
          clearInterval(intervalId);
          location.reload();
        }
      }, 1000);
    });
  }
  async startUpgrade() {
    this.isStart = true;
    await this.sleep(1000);
    this.upgradeLog = "Initializing the upgrade process.";
    this.percentUpgrade = 5;
    this.signalRService.startConnection().then(async (rs) => {
      if (rs) {
        this.signalRService.addUpgradeCallflowListener(this.uuid);
        await this.sleep(2000);
        this.settingClient
          .upgradeVersion(version, this.uuid)
          .subscribe((rs) => {});
      }
    });
    this.upgradeStatusSubscription =
      this.signalRService.upgradeCallflow.subscribe(async (rs) => {
        let parts: string[] = rs.split("|");
        let key: string = parts[0];
        let value: string = parts[1];
        switch (key) {
          case "version":
            this.currentUpgrade = value;
            this.percentUpgrade = 30;
            this.upgradeLog =
              "Downloading the upgrade version of the repository.";
            break;
          case "process":
            var processList = JSON.parse(value);
            this.lastLog = processList[processList.length - 1].title;
            processList.pop();
            for (const item of processList) {
              await this.simulateProcess(item.title, item.percent);
            }
            this.clientFinished = true;
            if (this.serverFinished) {
              this.percentUpgrade = 100;
              this.upgradeLog = this.lastLog;
            }
            break;
          case "result":
            this.serverFinished = true;
            this.result = value;
            if (this.clientFinished) {
              this.percentUpgrade = 100;
              this.upgradeLog = this.lastLog;
            }
            break;
        }
      });
  }

  ngOnDestroy(): void {
    this.upgradeStatusSubscription.unsubscribe();
    this.signalRService.stopHubConnection();
  }
}
