import { NbMenuService } from "@nebular/theme";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NbAuthService } from "@nebular/auth";

@Component({
  selector: "page-not-found",
  styleUrls: ["./page-not-found.component.scss"],
  templateUrl: "./page-not-found.component.html",
})
export class PageNotFoundComponent {
  constructor(private route: Router, private authService: NbAuthService) {
    document.title = "404 Not Found | Primas Bot Platform";
  }

  goToHome() {
    this.authService.getToken().subscribe((token) => {
      const temp = token.getPayload()["role"]?.split("-", 2);
      if (temp.length == 2) {
        if (temp[1] == "SuperAdmin") {
          this.route.navigate(["/portal/manage-dashboard"]);
        } else {
          this.route.navigate(["/portal/dashboard"]);
        }
      } else {
        this.route.navigate(["/portal/dashboard"]);
      }
    });
  }
}
