<div class="action-config-wrapper">
  <div class="config-action">
    <div>
      <div class="mb-2">
        <label class="form-label label required">Variable</label>
        <ngx-input-value-static 
          selectAttribute="true"
          [propertyInput]="data.attribute"
          (propertyEmit)="getAttribute($event)"
          [errorMessage]="errorMessage">
        </ngx-input-value-static>
      </div>
    </div>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
