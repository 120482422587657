import { IApplicationData } from "./common";
export class SendMail implements IApplicationData {
  constructor() {
    this.name = "Send Mail";
    this.emailRecipients = "";
    this.emailAddress = "";
    this.emailName = "";
    this.emailSubject = "";
    this.templateVariable = "";
    this.transition = true;
    this.template = 0;
    this.templateName = "";
    this.optionTemplate = 1;
    this.templateCustom = "";
    this.isRequired = true;
  }
  name: string;
  templateVariable: string;
  emailRecipients: string;
  emailName: string;
  templateCustom: string;
  emailAddress: string;
  emailSubject: string;
  transition: boolean;
  isRequired: boolean;
  template: number;
  templateName: string;
  optionTemplate: number;
}
