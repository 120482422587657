import { IApplicationData } from "./common";
export class CheckTime implements IApplicationData {
  constructor() {
    this.name = "Check Time";
    this.timezone = "";
    this.timezoneCustom = "";
    this.timezoneOption = 1;
    this.time = "";
    this.transition = true;
  }
  name: string;
  timezone: string;
  timezoneCustom: string;
  timezoneOption: number;
  time: string;
  transition: boolean;
}
