import { IApplicationData } from "./common";
export class OutreachAction implements IApplicationData {
  constructor() {
    this.name = "Outreach Action";
    this.callerId = "";
    this.phoneNumber = "";
    this.callflowId = "";
    this.callerNumber = "";
    this.flowName = "";
    this.attribute = "";
    this.outreachType = "phonecall";
    this.language = "";
    this.transition = true;
    this.isRequired = true;
    this.checkFields = false;
  }

  name: string;
  callflowId: string;
  phoneNumber: string;
  flowName: string;
  callerId: string;
  callerNumber: string;
  attribute: string;
  outreachType: string;
  language: string;
  transition: boolean;
  isRequired: boolean;
  checkFields: boolean;
}
