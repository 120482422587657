import { Injectable, EventEmitter, Output, Inject, InjectionToken, Optional } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { HttpClient } from '@angular/common/http';

export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');

@Injectable({
    providedIn: 'root'
})
export class ChatHubService {
    private baseUrl: string;
    @Output() chatResponse: EventEmitter<string> = new EventEmitter();

    constructor(private http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string){
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
    }
        
    public hubConnection: signalR.HubConnection

    public async startConnection() : Promise<any>{
        Object.defineProperty(WebSocket, 'OPEN', { value: 1, });     
        this.hubConnection = new signalR.HubConnectionBuilder().withUrl(this.baseUrl + "/hubs/chat").build();
        return this.hubConnection.start()
        .then(() => {
            return true;
        })
        .catch(err => {
            console.log('Error while starting connection: ' + err)
            return false;
         });
    }

    public chatResponseListener = (chatid) => {
        this.hubConnection.on(`chatResponse|` + chatid, (data) => {
            this.chatResponse.emit(data);
        });
    }
    public stopHubConnection()
    {
        this.hubConnection.stop();
    }
}