import {
  Component,
  OnInit,
  ViewChild,
  Directive,
  HostListener,
} from "@angular/core";
import { NbDialogService, NbWindowRef } from "@nebular/theme";
import { Subject } from "rxjs";
import { Subscription } from "rxjs/internal/Subscription";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { SayAttribute } from "../../../../@core/model/say-attribute";
import { SimpleAudioPlayerModalComponent } from "../../../../shared/simple-audio-player-modal/simple-audio-player-modal.component";
import { SimpleAudioPlayerComponent } from "../../../../shared/simple-audio-player/simple-audio-player.component";
import { AudioClient } from "../../../../System-api";
import { UntypedFormGroup } from "@angular/forms";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";

@Component({
  selector: "ngx-say-number",
  templateUrl: "./say-number.component.html",
  styleUrls: ["./say-number.component.scss"],
})
export class SayNumberComponent implements OnInit {
  @ViewChild("audioPlayer", {
    static: true,
  })
  audioPlayer: SimpleAudioPlayerComponent;
  cell: MxCell;
  graph: MxGraph;
  data: SayAttribute = new SayAttribute();
  selectedAudioSubsrciption: Subscription;
  eventsSubject: Subject<void> = new Subject<void>();
  cancelAction = false;
  formGroup: UntypedFormGroup;
  constructor(
    protected windowRef: NbWindowRef,
    private dialogService: NbDialogService,
    private audioClient: AudioClient,
    private formBuilder: RxFormBuilder,
    private graphService: GraphHandlerService
  ) {
    this.createForm();
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      number: ["", [RxwebValidators.required(), noWhitespaceValidator]],
    });
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  ngOnInit() {
    this.selectedAudioSubsrciption = this.audioPlayer.selectedAudio.subscribe(
      (audio) => {
        let audioPath = [];
        let audioId = [];
        if (audio) {
          audio.forEach((obj) => {
            audioPath.push(obj.pathAsterisk);
            audioId.push(obj.id);
          });
        }
        this.data.audioId = audio !== undefined ? audioId.toString() : "";
        this.data.audioPath = audio !== undefined ? audioPath.toString() : "";
      }
    );
    for (let [key, value] of Object.entries(this.data)) {
      this.data[key] = this.cell.getAttribute(key);
    }

    if (this.data.audioId !== "") {
      this.audioClient.get().subscribe((audioVm) => {
        this.audioPlayer.listAudioInAction = audioVm.audios;
        this.audioPlayer.playSelectedAudio(this.data.audioId);
      });
    }

    this.getDefaultSelect();
    this.audioPlayer.showAddButton = true;
    this.audioPlayer.isCallFlowAction = true;
    this.windowRef.onClose.subscribe(() => this.submit());
  }
  showModify() {
    let createAudio = this.dialogService.open(SimpleAudioPlayerModalComponent, {
      autoFocus: false,
      context: {
        showAddButton: true,
        audioListId: this.data.audioId.split(","),
      },
    });
    createAudio.componentRef.instance.onAdd.subscribe((rs) => {
      createAudio.close();
      this.eventsSubject.next(rs);
    });
  }
  getDefaultSelect() {
    if (this.data.reader == "") {
      this.data.reader = "0";
    }
    if (this.data?.type == "") {
      this.data.type = "0";
    }
  }

  drop($event) {
    this.data.number += "{" + $event.dataTransfer.getData("text").trim() + "}";
    $event.preventDefault();
  }

  submit() {
    if (!this.cancelAction) {
      for (let [key, value] of Object.entries(this.data)) {
        if (typeof this.data[key] == "string") {
          value = value?.trim();
        }
        this.cell.setAttribute(key, value || "");
      }
      const sayNumberCell = this.graph.getModel().getCell(this.cell.getId());
      const fieldsRequired = [this.data.number.trim()];
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        sayNumberCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }
}
