import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "bot" })
export class BotPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return "";
    }
    let dataDisplay = [];
    const data = JSON.parse(value);
    data.forEach((element) => {
      dataDisplay.push(` ${element.provider}-${element.contactId}`);
    });

    return dataDisplay.toString();
  }
}
