import { IApplicationData } from "./common";
export class LLM implements IApplicationData {
  constructor() {
    this.name = "LLMs";
    this.text = "";
    this.prompt = "";
    this.attribute = "";
    this.model = "gpt";
    this.temperature = 0.3;
    this.multipleCase = true;
    this.transition = true;
    this.isRequired = true;
    this.checkFields = false;
  }
  name: string;
  text: string;
  prompt: string;
  attribute: string;
  model: string;
  multipleCase: boolean;
  transition: boolean;
  isRequired: boolean;
  checkFields: boolean;
  temperature: number;
}
