import { Component, OnInit, ViewChild, Directive} from '@angular/core';
import { SimpleAudioPlayerComponent } from '../../../../shared/simple-audio-player/simple-audio-player.component';
@Component({
  selector: 'ngx-audio.index',
  templateUrl: './index-audio.component.html',
  styleUrls: ['./index-audio.component.scss'],
})
export class IndexAudioComponent implements OnInit{
  
  @ViewChild('audioPlayer', {static: true}) private audioPlayer: SimpleAudioPlayerComponent;
 

  constructor() { }

  ngOnInit(): void {
    this.audioPlayer.showAddButton = true;

  }

  refeshData(data)
  {
    this.audioPlayer.loadAudios(data);
  }
}