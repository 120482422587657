<nb-card style="margin-bottom: 0; max-width: 500px">
  <nb-card-header>
    <div class="d-flex justify-content-between">
      Notification
      <button
        *ngIf="
          processRequest !== 'grant' &&
          processRequest !== 'wait' &&
          processRequest !== 'deny'
        "
        (click)="dismiss('deny')"
        type="button"
        class="btn-close"
        aria-label="Close"
      ></button>
    </div>
  </nb-card-header>
  <nb-card-body
    [nbSpinner]="processRequest == 'wait'"
    nbSpinnerSize="giant"
    nbSpinnerStatus="primary"
  >
    <ng-container *ngIf="processRequest === 'wait'; else questionMain">
      <div [innerHTML]="questionWait"></div>
    </ng-container>
    <ng-template #questionMain>
      <div [innerHTML]="question"></div>
    </ng-template>
  </nb-card-body>
  <nb-card-footer>
    <div class="float-end">
      <ng-container *ngIf="processRequest === 'request'">
        <button
          (click)="readOnly()"
          type="button"
          size="small"
          class="me-2"
          status="info"
          nbButton
        >
          Read only
        </button>
        <button
          (click)="onSubmit()"
          type="submit"
          size="small"
          class="me-2"
          status="basic"
          nbButton
        >
          Save as a copy
        </button>
        <button
          (click)="requestPermission()"
          size="small"
          status="basic"
          nbButton
        >
          Request modification
        </button>
      </ng-container>

      <ng-container *ngIf="processRequest === 'wait'">
        <button (click)="cancelRequest()" size="small" status="basic" nbButton>
          Cancel
        </button>
      </ng-container>

      <ng-container *ngIf="processRequest === 'response'">
        <button
          (click)="dismiss('grant')"
          type="submit"
          size="small"
          class="me-2"
          status="info"
          nbButton
        >
          Yes
        </button>
        <button (click)="dismiss('deny')" size="small" status="basic" nbButton>
          No
        </button>
      </ng-container>
    </div>
  </nb-card-footer>
</nb-card>
