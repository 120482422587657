import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DataService } from "../../../../@core/utils/data.service";

@Component({
  selector: "sentiment-select",
  templateUrl: "sentiment-select.component.html",
  styleUrls: ["./sentiment-select.component.scss"],
})
export class SentimentSelectComponent implements OnInit {
  @Output() valueSelected = new EventEmitter<string>();
  @Input() value: string;
  @Input() defaultValue: string;
  @Input() placeHolder: string;
  listSentiment = [];
  constructor(private dataService: DataService) {}
  ngOnInit(): void {
    if(!this.placeHolder){
      this.placeHolder = "Select Sentiment";
    }
    this.getSentimentSetting();
    if (!this.value) {
      setTimeout(() => {
        this.valueSelected.emit(this.defaultValue);
      }, 0);
    } else {
      if (this.listSentiment) {
        let checkData = this.listSentiment.find((x) => this.value == x.name);
        if (!checkData) {
          setTimeout(() => {
            this.valueSelected.emit(this.defaultValue);
          }, 0);
        }
      }
    }
  }
  getSentimentSetting() {
    this.listSentiment = JSON.parse(this.dataService.CallSetting.media);
  }
  objectSelectedChange(value) {
    this.valueSelected.emit(value);
  }
}
