<div #container class="container-text">
  <div #backdrop class="backdrop">
    <div
      #highlight
      class="highlights"
      [innerHTML]="highlightedText"
      contenteditable
    ></div>
  </div>
  <textarea
    [placeholder]="placeHolder"
    [nbPopover]="templateRef"
    nbPopoverTrigger="noop"
    [(ngModel)]="textValue"
    (select)="onSelect($event)"
    (input)="onChangeContent($event)"
    (scroll)="handleScroll()"
    #textarea
  ></textarea>
</div>
<ng-template style="max-height: 200px !important" #templateRef>
  <div class="wrapper pop-up">
    <div class="item pop-up">
      <div style="width: 13rem" class="pop-up">
        <label
          for="{{ selectedText }}"
          class="form-label label overflow-label pop-up"
          >Entity Name For : {{ selectedText }}</label
        >
      </div>
      <input
        id="{{ selectedText }}"
        #autoInput
        nbInput
        type="text"
        [(ngModel)]="valueData"
        (ngModelChange)="onModelChange($event)"
        (keyup.enter)="handleEntity($event)"
        placeholder="Entity Name"
        [nbAutocomplete]="autoNgModel"
        class="text-input pop-up"
        fieldSize="small"
      />
      <nb-autocomplete size="small" #autoNgModel>
        <nb-option
          class="nb-option pop-up"
          *ngFor="let option of filteredOptions$ | async"
          [value]="option"
        >
          {{ option }}
        </nb-option>
      </nb-autocomplete>
    </div>
    <div class="item">
      <button
        type="button"
        status="primary"
        size="small"
        nbButton
        (click)="handleEntity({ target: { value: valueData } })"
      >
        <nb-icon
          icon="checkmark-circle-2-outline"
          style="pointer-events: none"
        ></nb-icon>
      </button>
    </div>
  </div>
</ng-template>
