<nb-layout>
  <nb-layout-column>
    <div id="notfound">
      <div class="notfound">
        <div class="notfound-404"></div>
        <h1>401</h1>
        <h2>Unauthorized</h2>
        <p>Oops, The Page you have requested can't authorized!</p>
        <button (click)="returnHomepage()" size="small" nbButton status="primary">
          <nb-icon icon="home-outline"></nb-icon>Back to homepage
        </button>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>
