import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { NbDialogService, NbWindowRef } from "@nebular/theme";
import { DialogAttributeComponent } from "../../other/dialog-attribute/dialog-attribute.component";
import { Observable, of } from "rxjs";
import { CustomEvent } from "../../../../@core/model/custom-event";
import { DataService } from "../../../../@core/utils/data.service";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";

@Component({
  selector: "custom-event",
  templateUrl: "./custom-event.component.html",
  styleUrls: ["./custom-event.component.scss"],
})
export class CustomEventComponent implements OnInit {
  listCondition;
  cell: MxCell;
  graph: MxGraph;
  data: CustomEvent = new CustomEvent();
  @ViewChild("autoInput") input;
  attributes: string[] = this.dataService.ivrAttributeArray;
  filteredOptions$: Observable<string[]>;
  cancelAction = false;
  errorMessage: string = "Variable is required.";
  constructor(
    private dialogService: NbDialogService,
    protected windowRef: NbWindowRef,
    public dataService: DataService,
    private graphService: GraphHandlerService
  ) {}
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  ngOnInit() {
    this.data.name = this.cell.getAttribute("name", "");
    this.filteredOptions$ = of(this.attributes);
    this.data.attribute = this.cell.getAttribute("attribute", "");
    this.windowRef.onClose.subscribe(() => this.submit());
  }

  submit() {
    if (!this.cancelAction) {
      this.cell.setAttribute(
        "attribute",
        this.data.attribute ? this.data.attribute : ""
      );
      this.cell.setAttribute("name", this.data.name ? this.data.name : "");

      const customEventCell = this.graph.getModel().getCell(this.cell.getId());
      const fieldsRequired = [this.data.attribute];
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        customEventCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }

  addAttribute() {
    this.dialogService
      .open(DialogAttributeComponent, { autoFocus: false })
      .onClose.subscribe((rs) => {
        if (rs) {
          this.data.attribute = rs;
        }
      });
  }
  private filter(value: string): string[] {
    const filterValue = value?.toLowerCase();
    return this.attributes.filter((optionValue) =>
      optionValue.toLowerCase().includes(filterValue)
    );
  }

  onModelChange(value: string) {
    this.filteredOptions$ = of(this.filter(value));
  }
  getAttribute(event) {
    this.data.attribute = event;
  }
}
