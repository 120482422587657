<nb-card size="large" class="update-dialog">
  <nb-card-header>Upgrade Version </nb-card-header>
  <nb-card-body style="overflow: hidden">
    <div style="height: 100%; width: 100%">
      <div *ngIf="!isStart">
        <div *ngFor="let version of listFeature | keyvalue">
          <h6>What news in {{ version.key }}</h6>
          <ul class="mb-3">
            <li *ngFor="let item of version.value">{{ item }}</li>
          </ul>
        </div>
      </div>

      <div style="height: 100%;" *ngIf="isStart">
        <p>Upgrate to version {{ currentUpgrade }}</p>
        <nb-progress-bar
          [value]="percentUpgrade"
          status="primary"
          [displayValue]="true"
        ></nb-progress-bar>
        <p>{{ upgradeLog }}</p>
        <textarea
          *ngIf="result != '' && percentUpgrade == 100"
          nbInput
          style="height: 85% !important; font-size: 12px"
          fullWidth
          placeholder="Result"
          readonly
          [(ngModel)]="result"
        ></textarea>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div *ngIf="!isStart">
      <button size="small" nbButton (click)="ref.close()">Close</button>
      <button
        size="small"
        style="float: right"
        status="primary"
        nbButton
        (click)="startUpgrade()"
      >
        Start
      </button>
    </div>

    <div *ngIf="percentUpgrade == 100">
      <p *ngIf="countDown >= 0">
        Please wait the web page will reload after {{ countDown }} second
      </p>
      <button
        size="small"
        style="float: right"
        *ngIf="countDown < 0"
        status="primary"
        nbButton
        (click)="completeUpgrade()"
      >
        Complete
      </button>
    </div>
  </nb-card-footer>
</nb-card>
