import { Component, OnInit } from "@angular/core";
@Component({
  selector: "hangup-action",
  templateUrl: "./hangup-action.component.html",
  styleUrls: ["./hangup-action.component.scss"],
})
export class HangupActionComponent implements OnInit {
 
  ngOnInit(): void {
   
  }

}
