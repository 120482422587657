import { Component, OnDestroy, OnInit } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { ResponseDefine } from "../../../../@core/model/response-define";

@Component({
  selector: "ngx-response-define",
  templateUrl: "./response-define.component.html",
  styleUrls: ["./response-define.component.scss"],
})
export class ResponseDefineComponent implements OnInit, OnDestroy {
  data: ResponseDefine = new ResponseDefine();

  constructor(private ref: NbDialogRef<ResponseDefine>) {}
  ngOnDestroy(): void {
    this.ref.close(this.data);
  }

  ngOnInit() {
    this.data.method = 'Press'
  }

  drop($event) {
    this.data.data +=
      "{" + $event.dataTransfer.getData("text").trim() + "}";
    $event.preventDefault();
  }

  dismiss() {
    this.ref.close(this.data);
  }
  
}
