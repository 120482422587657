import { NbDialogService } from "@nebular/theme";
import { DataService } from "../../../../../@core/utils/data.service";
import { Component, Input, OnInit } from "@angular/core";
import { ConfirmDialogComponent } from "../../../../../shared/confirm-dialog/confirm-dialog.component";
import { RxFormBuilder } from "@rxweb/reactive-form-validators";
import { ValidInput } from "../../../../../@core/utils/helpers";
import { UntypedFormGroup } from "@angular/forms";
@Component({
  selector: "setting-chatbot",
  templateUrl: "./setting-chatbot.component.html",
  styleUrls: ["./setting-chatbot.component.scss"],
})
export class SettingChatbotComponent implements OnInit {
  @Input() parentForm: UntypedFormGroup;
  @Input() callSetting;
  logoDefault = "../../../../../../assets/images/logo-bot-default.png";
  constructor(
    public dataService: DataService,
    private dialogService: NbDialogService,
    private formBuilder: RxFormBuilder
  ) {}

  ngOnInit() {}
}
